@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&family=Noto+Serif+JP:wght@400;500;600;700&display=swap');

//color
$color-brown1: #552b11;
$color-brown2: #cc9b30;
$color-brown3: #ddae46;
$color-brown4: #3b2e26;
$color-brown5: #715f54;
$color-brown6: #552b11;
$color-brown7: #9d9692;
$color-brown8: #574235;
$color-brown9: #49382e;
$color-brown10: #4f433c;

$color-yellow1: #fbc100;
$color-yellow2: #c7901a;

$color-red1: #da4a4a;

$color-blue1: #286da7;

$color-grey1: #888888;
$color-grey2: #f5f5f5;
$color-grey3: #d0d0d0;
$color-grey4: #bbbbbb;
$color-grey5: #625851;
$color-grey6: #aa9588;

//font-family
$noto-sans: "Noto Sans JP", sans-serif;
$noto-serif: "Noto Serif JP", serif;

//font-weight
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$black: 900;

//opacity
$opacity: 0.7;

//transition
$transition-time: 0.3s;

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  #MainImg{
    position: relative;
    &::after{
      content: "";
      display: block;
      background-color: $color-brown4;
      position: absolute;
      left: 0;
      z-index: -1;
    }
    #MainImgInner{
      height: 100%;
      .inner{
        position: relative;
      }
      h2, p{
        font-family: $noto-serif;
        font-weight: $semibold;
        color: #fff;
        display: inline-block;
        position: absolute;
        b{
          position: relative;
          &::after{
            content: "";
            position: absolute;
            transform: skewX(-15deg);
            background-color: $color-brown4;
            z-index: 1;
          }
          em{
            position: relative;
            z-index: 2;
          }
        }
      }
      h2{
        text-align: left;
        position: absolute;
      }
      p{
        position: absolute;
        span{
          color: $color-yellow1;
        }
      }
    }
  }
  #ContBox01{
    background-color: $color-grey2;
    position: relative;
    &::after{
      content: "";
      display: block;
      background-color: $color-grey2;
      position: absolute;
      right: 0;
      z-index: -1;
    }
    .contSubBox01{

    }
  }
  #ContBox02{
    overflow: hidden;
    position: relative;
    &::after{
      content: "";
      display: block;
      background-color: $color-grey2;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: -1;
    }
    .contSubBox01{
      ul{
        li{
          a{
            text-decoration: none;
            figure{
              position: relative;
              &::after{
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 60px;
                height: 60px;
                background-color: $color-brown4;
                background-image: url(../img/contents/top/arrow01.png);
                background-size: 18px auto;
                background-repeat: no-repeat;
                background-position: center center;
              }
              img{
                width: 100%;
              }
            }
            dl{
              background-color: #fff;
              dt{
                font-family: $noto-serif;
                font-weight: $semibold;
                color: $color-yellow2;
              }
              dd{
                color: #222;
              }
            }
          }
        }
      }
    }
  }
  #ContBox03{
    .contSubBox01{
      .bgImg{
        background: url(../img/contents/top/img_05.jpg) no-repeat center center;
        background-size: cover;
      }
      .blogList{
        .list{
          font-family: $noto-serif;
          a{
            color: #fff;
            display: block;
            text-decoration: none;
            text-align: left;
            background-image: url(../img/contents/top/icon_btn_top.png);
            background-repeat: no-repeat;
            small{
              display: block;
            }
          }
        }
        .list01{
          a{
            font-weight: $semibold;
          }
        }
        .list02{
          display: flex;
          flex-wrap: wrap;
          a{
            width: 50%;
            border-top: 1px solid $color-grey5;
            &:nth-of-type(1){
              border-right: 1px solid $color-grey5;
            }
          }
        }
        .list01, .list02{
          a{
            background-color: $color-brown4;
          }
        }
        .list03{
          a{
            background-color: $color-brown2;
            display: flex;
            flex-wrap: wrap;
            figure{
              width: 70px;
              margin-right: 50px;
              img{
                width: 100%;
                height: auto!important;
              }
            }
            span{
              width: calc(100% - 120px);
            }
          }
        }
      }
    }
  }
  #ContBox04{
    .contSubBox01{
      h3{
      }
      .btnTypeBasic{
        text-align: left;
      }
    }
    .contSubBox02{
      .post{
        li{
          border-top: 1px solid $color-grey3;
          &:last-of-type{
            border-bottom: 1px solid $color-grey3;
          }
          .date{
            color: $color-brown2;
          }
          .postCont{
            a{
              color: #222;
              text-decoration: none;
            }
            h4{
              font-weight: $bold;
              line-height: 1.8;
            }
            p{
              color: $color-grey1;
            }
            .linkMore{
              color: $color-blue1;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  #ContBox05{
    iframe{
      width: 100%;
    }
    .contSubBox01{
      h4{
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(../img/contents/top/address_logo.png);
        text-indent: -9999px;
      }
      &>p{
        font-size: 14px;
      }
    }
    .contSubBox02{
      
    }
  }

  
}

//下層メインイメージ
#PageFeature,
#PageBusiness,
#PageBunkatsu,
#PageAdr,
#PageOffice,
#PageBooks,
#PageBlog,
#PageContact,
#PagePolicy{
  #MainImg{
    background-color: $color-brown4;
    position: relative;
    h2{
      position: absolute;
      top: 50%;
      transform: translate(0 ,50%);
      text-align: left;
    }
  }
}

/*
当法律事務所の強み
-------------------------------------*/
#PageFeature.pageIndex {
  .contBox01{
    .contSubBox01{
      img{
        
      }
      .infoBox{
        ul{
          border: 1px solid $color-brown1;
          li{
            font-weight: $bold;
            color: $color-brown1;
          }
        }
      }
    }
  }
  .contBox02{
    background-color: $color-grey2;
    .contSubBox01{
      ul{
        li{
          background-color: #fff;
          position: relative;
          &::after{
            content: "";
            display: block;
            background-color: $color-yellow2;
            color: #fff;
            font-family: $noto-serif;
            font-weight: $semibold;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
          }
          dl{
            dt{
              color: $color-yellow2;
              font-family: $noto-serif;
              font-weight: $semibold;

            }
            dd{
              a{
                color: $color-red1;;
              }
            }
          }
          &:nth-of-type(4) dl dt{
            position: relative;
            &[data-subtitle]{
              &::after{
                content: attr(data-subtitle);
                font-size: 14px;
                position: absolute;
                top: -20px;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}

/*
取扱業務
-------------------------------------*/
#PageBusiness.pageIndex {
  .yellowTxt{
    font-weight: $bold;
  }
}

/*
会社分割
-------------------------------------*/
#PageBunkatsu.pageIndex {
  .contBox01{
    .contSubBox01{
      figure{
        img{
          width: 100%;
          height: auto;
        }
        figcaption{
          color: $color-grey1;
          font-size: 14px;
        }
      }
    }
  }
  .contBox02,
  .contBox03{
    .contSubBox01{
      .titleTypeBg{

      }
      .txtBox{
        .titleTypeBasic{

        }
        ul{
          li{

          }
        }
      }
    }
  }
  .contBox04{
    .contSubBox01{
      img{
      }
      .txtBox{
      }
    }
  }
  .contBox05{
    position: relative;
    &::after{
      content: "";
      display: block;
      background-color: $color-grey2;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: -1;
    }
    .contSubBox01{
      background-color: #fff;
      border: 1px solid $color-brown1;
      .txtBox{
        background-color: $color-grey2;
        h5{
          color: $color-brown1;
          font-weight: $bold;
          text-align: center;
        }
        p{
          color: $color-brown1;
        }
      }
      .txtList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          background-color: $color-brown4;
          color: #fff;
          text-align: center;
          font-family: $noto-serif;
          font-weight: $semibold;
          position: relative;
          &::after{
            content: "";
            background: url(../img/contents/bunkatsu/arrow01.png) no-repeat center center;
            background-repeat: no-repeat;
            background-size: 32px auto;
            width: 32px;
            height: 15px;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .contSubBox02{
      background-color: $color-brown2;
      color: #fff;
      &>p{
        font-family: $noto-serif;
        font-weight: $semibold;
        position: relative;
        &::after{
          content: "";
          background-color: #fff;
          background-repeat: no-repeat;
          background-size: 1px 50px;
          width: 1px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      p{
        text-align: center;
      }
      .box01{
        border-bottom: 1px solid #fff;
        .tel{
          font-family: $noto-serif;
          font-weight: $bold;
          a{
            color: #fff;
          }
        }
      }
      .box02{
        .mail{
          a{
              color: #fff;
              text-decoration: none;
          }
        }
      }
    }
  }
}

/*
日本企業再建研究会
-------------------------------------*/
#PageAdr.pageIndex {
  .contBox01{
    .contSubBox01{
      dl{
        color: $color-brown1;
        border-top: 1px solid $color-brown1;
        border-bottom: 1px solid $color-brown1;
        dt{
          font-family: $noto-serif;
          font-weight: $semibold;
        }
        dd{

        }
      }
      figure{
        width: 100%;
        a{
          display: block;
        }
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    .contSubBox02{
      p{

      }
    }
  }
  .contBox02{
    .contSubBox01{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

/*
事務所案内
-------------------------------------*/
#PageOffice.pageIndex {
  .contBox01{
    ul{
      li{
        a{
          color: $color-brown1;
          text-decoration: none;
          display: block;
          background: url(../img/contents/office/arrow01.png) no-repeat center bottom;
        }
      }
    }
  }
  .contBox02{
    .contSubBox03{
      background-color: $color-grey2;
    }
    .contSubBox04{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  .contBox04{
    .contSubBox{
      table{
        width: 100%;
        tr{
          &:nth-of-type(odd){
            background-color: $color-grey2;
          }
          th{
            font-weight: $regular;
          }
          td{

          }
        }
      }
    }
  }
  .contBox05{
    position: relative;
    &::after{
      content: "";
      display: block;
      background-color: $color-grey2;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: -1;
    }
    .contSubBox01{
      h4{
        color: $color-brown1;
        font-family: $noto-serif;
        font-weight: $semibold;
        text-align: center;
      }
      p{
        text-align: center;
      }
      .txtBox{
        background-color: $color-brown2;
        color: #fff;
        .box01{
          border-bottom: 1px solid #fff;
          .tel{
            font-family: $noto-serif;
            font-weight: $bold;
            a{
              color: #fff;
            }
          }
        }
        .box02{
          .mail{
            a{
                color: #fff;
                text-decoration: none;
            }
          }
        }
      }
    }
    .contSubBox02{
      .box{
        .txtBox{
          background-color: #fff;
          border: 1px solid $color-brown4;
          position: relative;
          &::after{
            content: "";
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
          }
          h4, p{
            color: $color-brown1;
            text-align: center;
          }
          h4{
            font-weight: $bold;
          }
        }
      }
    }
    .contSubBox03{
      .txtBoxBody{
        .txtBox{
          background-color: $color-grey2;
          h5{
            color: $color-brown1;
            font-weight: $bold;
            text-align: center;
          }
          p{
            color: $color-brown1;
          }
        }
        .txtList{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            background-color: $color-brown4;
            color: #fff;
            text-align: center;
            font-family: $noto-serif;
            font-weight: $semibold;
            position: relative;
            &::after{
              content: "";
              background: url(../img/contents/bunkatsu/arrow01.png) no-repeat center center;
              background-repeat: no-repeat;
              background-size: 32px auto;
              width: 32px;
              height: 15px;
              position: absolute;
              top: -20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

/*
著書・論文
-------------------------------------*/
#PageBooks.pageIndex {
  .contBox01{
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li{
        a{
          color: $color-brown1;
          text-decoration: none;
          display: block;
          background: url(../img/contents/office/arrow01.png) no-repeat center bottom;
        }
      }
    }
  }
}

/*
後藤孝典が語る メインイメージ
-------------------------------------*/
#PageGototakanori {
  #MainImg{
    background-color: $color-brown4;
    position: relative;
    #MainImgInner{
      .imgBox{
        background: url(../img/contents/gototakanori/main_visu.jpg) no-repeat right center;
        background-size: cover;
      }
      h2{
        background: url(../img/contents/top/title_logo.png) no-repeat left top;
        background-size: 32px auto;
        &::before{
          content: "";
          display: block;
          background-color: $color-grey3;
          width: 50px;
          height: 1px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      p{
        color: #fff;
      }
    }
  }
}

/*
後藤孝典が語る
-------------------------------------*/
#PageGototakanori.pageIndex {
  .contBox01{
    .contSubBox01{
      .post{
        .postCont{
          background-color: $color-grey2;
          border: 1px solid $color-brown4;
          .date{
            color: $color-brown2;
          }
          .title{
            color: $color-brown1;
            font-weight: bold;
          }
        }
      }
    }
  }
}

/*
後藤孝典が語る（記事詳細）
-------------------------------------*/
#PageGototakanori.pageEntry {
  .contBox01{
    .contSubBox01{
      background-color: $color-grey2;
      border: 1px solid $color-brown4;
      .date{
        color: $color-brown2;
      }
      .titleTypeBasic{
      }
    }
  }
}


/*
お知らせブログ（カテゴリ）
-------------------------------------*/
#PageBlog.pageCategory {
  .contBox01{
    .entryType01{
      dl{
        &:nth-of-type(odd){
          background-color: $color-grey2;
        }
        a{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          text-decoration: none;
          color: #222;
        }
        .date{
          color: $color-brown2;
          text-align: center;
          width: 20%;
          line-height: 1.8;
        }
        .title{
          width: 80%;
          line-height: 1.8;
        }
      }
    }
    .entryType02{
      .post{
        .postCont{
          background-color: $color-grey2;
          border: 1px solid $color-brown4;
          .date{
            color: $color-brown2;
          }
          .title{
            color: $color-brown1;
            font-weight: bold;
          }
        }
      }
    }
  }
}

/*
お知らせブログ（記事詳細）
-------------------------------------*/
#PageBlog.pageEntry {
  .contBox01{
    .contSubBox01{
      background-color: $color-grey2;
      border: 1px solid $color-brown4;
      .date{
        color: $color-brown2;
      }
      .titleTypeBasic{
      }
    }
  }
}


/*
お問い合わせ（共通）
-------------------------------------*/
#PageContact {
    #Main{
        .error_messe{
            text-align: center;
            color: $color-brown2;
        }
        .formBox{
            .tableTypeForm{
                width: 100%;
                tbody{
                    tr{
                        th{
                            position: relative;
                            font-weight: 400;
                            &::before{
                                content: "必須";
                                position: absolute;
                                left: 0;
                                display: block;
                                box-sizing: border-box;
                                background-color: $color-brown2;
                                line-height: 1;
                                text-align: center;
                                color: #fff;
                            }
                        }
                        td{
                            input[type="text"], 
                            input[type="email"], 
                            input[type="tel"], 
                            textarea{
                                border: 1px solid $color-brown4;
                                border-radius: 0 !important;
                                background-color: #fff;
                                -webkit-appearance: none !important;
                            }
                            input[type="text"], 
                            input[type="email"], 
                            input[type="tel"], 
                            textarea{
                                display: block;
                                width: 100%;
                            }
                            textarea{
                                resize: vertical;
                            }
                        }
                        &.noAttention{
                            th{
                                &::before{
                                    content: "任意";
                                    border: 1px solid $color-brown2;
                                    background-color: #fff;
                                    color: $color-brown2;
                                }
                            }
                        }
                    }
                }
            }
        }
        .listTypeButton{
            text-align: center;
            li{
                &.submit, &.back{
                    .btnTypeBasic{
                        border-radius: 0 !important;
                        padding-top: 0;
                        letter-spacing: .1em;
                        font-family: $noto-sans;
                        font-size: 16px;
                        &::after{
                            display: none;
                        }
                    }
                }
                &.back{
                    margin-top: 50px;
                }
            }
        }
        .txtTypeCenter{
            &+ .error_messe{
                margin-top: 40px;
            }
        }   
    }
}

/*
お問い合わせ（入力画面）
-------------------------------------*/
#PageContact.pageIndex {
}

/*
お問い合わせ（確認画面）
-------------------------------------*/
#PageContact.pageConfirm {
    #Main{
        .formBox{
            .tableTypeForm{
                tbody{
                    tr{
                        &:nth-of-type(4){
                            th{
                                &::before{
                                    content: "任意";
                                    border: 1px solid $color-brown2;
                                    background-color: #fff;
                                    color: $color-brown2;
                                }
                            }
                        }
                    }
                }
            }
        }
        .txtTypeCenter{
            margin-top: 30px;
            text-align: center;
            margin-bottom: 0;
        }
    }
}

/*
お問い合わせ（完了画面）
-------------------------------------*/
#PageContact.pageThanks {
    #Main{
        p{
            text-align: center;
        }
    }
}



@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg{
      height: 910px;
      padding-left: 50px;
      padding-bottom: 50px;
      &::after{
        bottom: -50px;
        height: 820px;
        width: calc(100% - 60px);
      }
      #MainImgInner{
        background: url(../img/contents/top/main_visu.jpg) no-repeat center center;
        background-size: cover;
        h2{
          font-size: 60px;
          top: 295px;
          left: 100px;
          b{
            &::after{
              height: 106px;
              width: 650px;
              top: -5px;
              left: -45px;
            }
          }
        }
        p{
          font-size: 24px;
          top: 433px;
          left: 100px;
          &.disp_sp{
            display: none;
          }
          b{
            &::after{
              height: 70px;
              width: 784px;
              top: -18px;
              left: -40px;
            }
          }
        }
      }
    }
    #ContBox01{
      padding: 120px 0;
      &::after{
        top: -50px;
        width: 60px;
        height: 50px;
      }
      .contSubBox01{
        .titleTypeBasic{
          padding: 26px 0;
          margin-bottom: 40px;
        }
        .entryBox{
          margin-bottom: 48px;
          padding: 0 50px;
          img{
            width: 167px!important;
            height: auto!important;
            margin-right: 50px!important;
            display: block!important;
          }
        }
      }
    }
    #ContBox02{
      padding: 200px 0 120px;
      &::after{
        height: calc(100% - 615px);
        top: 615px;
      }
      h3{
        margin-bottom: 70px;
      }
      .contSubBox01{
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            width: calc(50% - 20px);
            margin-bottom: 60px;
            a{
              &:hover{
                opacity: 1.0;
                figure {
                  &::after{
                    background-color: $color-brown8;
                  }
                  &> img{
                    transform: scale(1.1);
                  }
                }
              }
              figure{
                overflow: hidden;
                &::after{
                  transition: all 0.3s;
                  background-size: 12px auto;
                  background-position: left 27px center;
                }
                img{
                  transition: all 0.3s;
                  height: 260px;
                }
              }
              dl{
                padding: 30px 30px;
                dt{
                  font-size: 28px;
                  margin-bottom: 10px;
                }
                dd{
                  line-height: 2.0;
                }
              }
            }
          }
        }
      }
    }
    #ContBox03{
      padding: 150px 0 0;
      h3{
        margin-bottom: 30px;
      }
      .contSubBox01{
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        .bgImg{
          width: calc(100% - 520px);
          //height: 480px;
        }
        .blogList{
          width: 520px;
          .list{
            a{
              transition: all 0.3s;
              &:hover{
                opacity: 1.0;
              }
              small{
              }
            }
          }
          .list01,
          .list02 {
            a:hover{
              background-color: $color-brown9;
            }
          }
          .list01{
            a{
              font-size: 24px;
              padding: 70px 140px 70px 100px;
              background-size: 58px auto;
              background-position: center right 85px;

            }
          }
          .list02{
            a{
              font-size: 20px;
              padding: 50px 60px 50px 30px;
              background-size: 26px auto;
              background-position: center right 30px;
              small{
                font-size: 12px;
                line-height: 2.0;
              }
            }
          }
          .list03{
            a{
              font-size: 20px;
              padding: 25px 60px 25px 40px;
              background-size: 26px auto;
              background-position: center right 55px;
              &:hover{
                background-color: $color-brown3;
              }
              span{
                padding: 35px 0;
                display: block;
              }
            }
          }
        }
      }
    }
    #ContBox04{
      padding: 160px 0 0;
      .innerBasic{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .contSubBox01{
        width: 290px;
        h3{
          margin-top: 20px;
          margin-bottom: 55px;
        }
        .btnTypeBasic{
          width: 210px;
          margin: 0;
          padding: 23px 30px 23px 30px;
          span{
            &::after{
              right: -25px;
            }
          }
        }
      }
      .contSubBox02{
        width: calc(100% - 290px);
        .post{
          li{
            padding: 42px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            a:hover{
              .linkMore{
                text-decoration: none;
              }
            }
            .date{
              width: 110px;
            }
            .postCont{
              width: calc(100% - 110px);
              h4{
                font-size: 18px;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
    #ContBox05{
      padding: 160px 0 200px;
      .innerBasic{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .contSubBox01{
        width: 505px;
        h3{
          margin-top: 20px;
          margin-bottom: 53px;
        }
        img{
          width: 456px;
          height: 54px;
          margin-bottom: 10px;
        }
        .address{
          margin-top: 46px;
          p{
            line-height: 1.8;
            &:nth-of-type(1){
              margin-bottom: 18px;
            }
          }
        }
      }
      .contSubBox02{
        width: calc(100% - 505px);
        iframe{
          height: 440px;
        }
      }
    }
  }

  //下層メインイメージ
  #PageFeature,
  #PageBusiness,
  #PageBunkatsu,
  #PageAdr,
  #PageOffice,
  #PageBooks,
  #PageBlog,
  #PageContact,
  #PagePolicy{
    #MainImg{
      height: 390px;
      padding-top: 110px;
    }
  }

  /*
  当法律事務所の強み
  -------------------------------------*/
  #PageFeature.pageIndex {
    .contBox01{
      padding: 110px 0 115px;
      .contSubBox01{
        width: 1000px;
        margin: 0 auto;
        .wrap{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 108px;
        }
        .wrap.disp_sp{ display: none }
        img{
          width: 323px;
          height: auto;
          margin-right: 50px;
        }
        .infoBox{
          width: calc(100% - 373px);
          margin-top: 20px;
          ul{
            padding: 35px 55px;
            margin-bottom: 60px;
            li{
              font-size: 20px;
              line-height: 1.9;
              &::after{
                top: 16px;
              }
            }
          }
          .txtBox{
            position: relative;
            &::after{
              content: "";
              display: block;
              position: absolute;
              top: 10px;
              right: 0;
              background: url(../img/contents/feature/img_02.jpg) no-repeat center center;
              background-size: 130px auto;
              width: 130px;
              height: 130px;
            }
            p{
              &:nth-of-type(1){
                margin-bottom: 52px;
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: -40px;
                  left: 0;
                  width: 469px;
                  height: 21px;
                  background: url(../img/contents/feature/arrow01.png) no-repeat center center;
                  background-size: 469px auto;
                }
              }
            }
          }
        }
      }
    }
    .contBox02{
      padding: 140px 0 150px;
      .contSubBox01{
        width: 1000px;
        margin: 0 auto;
        ul{
          li{
            padding: 50px 100px 60px;
            margin-bottom: 50px;
            &::after{
              width: 60px;
              height: 60px;
              line-height: 60px;
              font-size: 28px;
            }
            &:nth-of-type(1)::after{
              content: "1";
            }
            &:nth-of-type(2)::after{
              content: "2";
            }
            &:nth-of-type(3)::after{
              content: "3";
            }
            &:nth-of-type(4){
              padding-top: 77px;
              &::after{
                content: "4";
              }
              dl dd p{
                margin-bottom: 20px;
                &:last-of-type{
                  margin-bottom: 0;
                }
                .redTxt{
                  text-decoration: underline;
                }
              }
            }
            dl{
              dt{
                font-size: 28px;
                margin-bottom: 30px;
              }
              dd{
                line-height: 2.0;
              }
            }
          }
        }
      }
    }
  }

  /*
  取扱業務
  -------------------------------------*/
  #PageBusiness.pageIndex {
     #Main{
      .titleTypeBasic{
        margin: 20px 0 30px;
        padding: 15px 0;
      }
      .contBox01{
        padding-top: 115px;
        padding-bottom: 115px;
      }
    }
  }

  /*
  会社分割
  -------------------------------------*/
  #PageBunkatsu.pageIndex {
    .titleTypeBg{
      padding: 28px 40px;
      font-size: 24px;
      margin-bottom: 48px;
    }
    .contBox01{
      padding: 120px 0 80px;
      .contSubBox01{
        figure{
          img{
          }
          figcaption{
            text-align: center;
            margin-top: 5px;
            margin-bottom: 48px;
          }
        }
        .txtBox{
          width: 800px;
          margin: 0 auto;
          p{
            margin-bottom: 30px;
          }
        }
      }
    }
    .contBox02,
    .contBox03{
      .contSubBox01{
        .txtBox{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-right: 40px;
          padding-left: 40px;
          margin-bottom: 85px;
          .wrap{
            width: calc(50% - 20px);
          }
          .titleTypeBasic{
            padding: 15px 0;
            margin-bottom: 20px;
          }
          p{
            margin-bottom: 25px;
          }
          ul{
            li{
              margin-bottom: 6px;
            }
          }
        }
      }
    }
    .contBox04{
      .contSubBox01{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px;
        padding-left: 40px;
        margin-bottom: 165px;
        img{
          width: 350px;
        }
        .txtBox{
          width: calc(100% - 400px);
          p{
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .contBox05{
      padding-bottom: 200px;
      padding: 0 0 120px;
      &::after{
        height: calc(100% - 395px);
        top: 395px;
      }
      .contSubBox01{
        padding: 100px 100px;
        margin-bottom: 95px;
        .txtBox{
          padding: 40px 30px 45px;
          margin-bottom: 105px;
          h5{
            font-size: 20px;
            margin-bottom: 15px;
          }
          p{
            text-align: center;
          }
        }
        .txtList{
          li{
            width: calc(calc(100% - 80px) / 3);
            padding: 55px 30px;
            font-size: 20px;
            &::after{
              background-size: 43px auto;
              width: 43px;
              height: 21px;
              top: -60px;
            }
          }
        }
      }
      .contSubBox02{
        padding: 70px 20px;
        &>p{
          font-size: 20px;
          margin-bottom: 70px;
          &::after{
            height: 50px;
            bottom: -70px;
          }
        }
        p{
          text-align: center;
        }
        .box01{
          width: 320px;
          margin: 0 auto;
          padding-bottom: 22px;
          .tel{
            font-size: 46px;
            a{
            }
          }
          .fax{
            margin-top: -22px;
          }
        }
        .box02{
          .mail{
            margin-top: 10px;
            a{
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  /*
  日本企業再建研究会
  -------------------------------------*/
  #PageAdr.pageIndex {
    .contBox01{
      padding: 115px 0 80px;
      .innerBasic{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .contSubBox01{
        width: 460px;
        dl{
          padding: 50px 30px 55px;
          margin-bottom: 50px;
          margin-top: 10px;
          dt{
            font-size: 28px;
            letter-spacing: 0em;
            margin-bottom: 28px;
          }
          dd{
            font-size: 12px;
            line-height: 2.2;
          }
        }
        figure{
          a{
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
            &:hover{
              opacity: $opacity;
            }
          }
        }
      }
      .contSubBox02{
        width: calc(100% - 540px);
        p{
          margin-bottom: 30px;
        }
      }
    }
    .contBox02{
      padding-bottom: 195px;
      .contSubBox01{
        ul{
          li{
            width: calc(calc(100% - 40px) / 6);
            margin-bottom: 8px;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  事務所案内
  -------------------------------------*/
  #PageOffice.pageIndex {
    .titleTypeBg{
      padding: 27px 40px;
      font-size: 24px;
      margin-bottom: 65px;
    }
    .titleTypeBasic{
      padding: 15px 0;
      margin-bottom: 20px;
      &+p{
        margin-bottom: 20px;
      }
    }
    .titleTypeBorder{
      font-size: 20px;
      padding: 17px 47px;
      margin-bottom: 40px;
      span{
        font-size: 16px;
        position: relative;
        top: -2px;
      }
    }
    .contBox01{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 112px 0 124px;
        li{
          margin-right: 92px;
          &:last-of-type{
            margin-right: 0;
          }
          a{
            text-align: center;
            padding-bottom: 16px;
            background-size: 12px auto;
          }
        }
      }
    }
    .contBox02{
      .contSubBox01{
        padding: 0 40px 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        img{
          width: 350px;
          height: auto;
        }
        .txtBox{
          width: calc(100% - 400px);
          padding-top: 10px;
          p{
            margin-bottom: 30px;
          }
          .align-right{
            font-size: 16px;
            span{
              font-size: 14px;
            }
          }
        }
      }
      .contSubBox03{

        background-color: $color-grey2;
      }
      .contSubBox04{
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }
    .contBox03{
      .titleTypeBg{
        margin-bottom: 50px;
      }
      .contSubBox01,
      .contSubBox02{
        padding: 0 40px;
        .txtBox{
          ul{
            li{
              margin-bottom: 6px;
              &:last-of-type{
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .contSubBox01{
        margin-bottom: 55px;
        .txtBox{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .wrap{
            width: calc(calc(100% - 40px) / 2);
          }
        }
      }
      .contSubBox02{
        margin-bottom: 30px;
      }
      .contSubBox03{
        background-color: $color-grey2;
        width: calc(100% - 80px);
        margin: 0 auto;
        padding: 70px 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        ul{
          width: calc(calc(100% - 40px) / 2);
          li{
            margin-bottom: 22px;
            &:last-of-type{
              margin-bottom: 0;
            }
            dl{
              dt{
                font-size: 18px;
                line-height: 1.8;
              }
              dd{
                padding-left: 25px;
                line-height: 2.0;
              }
            }
          }
        }
      }
      .contSubBox04{
        padding: 50px 40px 100px;
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            width: calc(calc(100% - 60px) / 8);
            img{
              width: 133px;
              height: auto;
            }
          }
        }
      }
    }
    .contBox04{
      .titleTypeBg{
        margin-bottom: 50px;
      }
      .contSubBox{
        padding: 0 40px;
        .wrap{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 50px;
        }
        img{
          width: 350px;
          height: 420px;
        }
        .tableWrap{
          width: calc(100% - 400px);
        }
        table{
          tr{
            th{
              width: 23%;
              padding: 15px 30px;
            }
            td{
              width: 77%;
              padding: 15px 30px 15px 0;
            }
          }
        }
      }
      .contSubBox02{
        margin-bottom: 100px;
      }
    }
    .contBox05{
      &::after{
        height: calc(100% - 340px);
        top: 340px;
      }
      .titleTypeBg{
        margin-bottom: 50px;
      }
      .contSubBox01{
        h4{
          font-size: 24px;
        }
        &>p{
          font-size: 14px;
          margin-bottom: 22px;
        }
        .txtBox{
          padding: 20px 0 45px;
          width: 610px;
          margin: 0 auto;
          .box01{
            width: 320px;
            margin: 0 auto;
            padding-bottom: 22px;
            .tel{
              font-size: 46px;
              a{
              }
            }
            .fax{
              margin-top: -22px;
            }
          }
          .box02{
            .mail{
              margin-top: 10px;
              a{
                &:hover{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .contSubBox02{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 60px 40px 80px;
        .box{
          width: calc(50% - 20px);
          .txtBox{
            padding: 44px 50px;
            h4, p{
            }
            h4{
              font-size: 20px;
              margin-bottom: 8px;
            }
          }
        }
        .box01{
          .txtBox{
            &::after{
              background: url(../img/contents/office/arrow02.png) no-repeat center center;
              background-size: 43px auto;
              width: 43px;
              height: 223px;
              bottom: -253px;
            }
          }
        }
        .box02{
          .txtBox{
            &::after{
              background: url(../img/contents/office/arrow03.png) no-repeat center center;
              background-size: 43px auto;
              width: 43px;
              height: 21px;
              bottom: -50px;
            }
            &:nth-of-type(1){
              margin-bottom: 78px;
            }
          }
        }
      }
      .contSubBox03{
        padding: 90px 40px 80px;
        background-color: #fff;
        border: 1px solid $color-brown1;
        width: calc(100% - 80px);
        margin: 0 auto;
        &+p{
          text-align: center;
          font-size: 14px;
          margin-top: 10px;
        }
        .txtBoxHead{
          padding: 0 50px 50px;
          p{
            text-align: center;
            margin-bottom: 20px;
          }
          ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 20px;
            li{
              background-color: $color-brown2;
              color: #fff;
              text-align: center;
              padding: 8px 0;
              margin-right: 25px;
              width: 160px;
              &:last-of-type{
                margin-right: 0;
              }
            }
          }
        }
        .txtBoxBody{
          padding: 0 20px;
          .txtBox{
            padding: 40px 30px 45px;
            margin-bottom: 105px;
            h5{
              font-size: 20px;
              margin-bottom: 15px;
            }
            p{
              text-align: center;
            }
          }
          .txtList{
            li{
              width: calc(calc(100% - 80px) / 3);
              padding: 55px 30px;
              font-size: 20px;
              &::after{
                background-size: 43px auto;
                width: 43px;
                height: 21px;
                top: -60px;
              }
            }
          }
        }
      }
      .contSubBox04{
        padding: 95px 40px 80px;
        .titleTypeBgYe{
          font-size: 20px;
          padding: 15px 30px;
          margin-bottom: 20px;
        }
        &>ul{
          &>li{
            p{
              margin-bottom: 30px;
              &:last-of-type{
                margin-bottom: 45px;
              }
            }
            &>ul{
              margin-bottom: 30px;
              &>li{
                margin-bottom: 8px;
                &:last-of-type{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .contBox06{
      padding: 95px 0 170px;
      .titleTypeBg{
        margin-bottom: 40px;
      }
      .contSubBox01{
        padding: 0 40px;
        p{
          margin-bottom: 35px;
        }
        iframe{
          width: 100%;
          height: 440px;
        }
        .linkTypeBlank{
          width: 150px;
          margin-bottom: 8px;
        }
        ul{
          margin-bottom: 25px;
          li{
            margin-bottom: 8px;
          }
          &+p{
            font-size: 14px;
          }
        }
      }
    }

  }

  /*
  著書・論文
  -------------------------------------*/
  #PageBooks.pageIndex {
    .titleTypeBg{
      font-size: 24px;
      padding: 26px 40px;
    }
    .titleTypeBasic{
      font-size: 20px;
      padding: 15px 0;
      margin-bottom: 20px;
    }
    .contBox01{
      padding: 110px 0 120px;
      ul{
        li{
          margin-right:100px;
          &:last-of-type{
            margin-right: 0;
          }
          a{
            padding-bottom: 15px;
            background-size: 12px auto;
          }
        }
      }
    }
    .contBox02{
      .contSubBox01{
        padding: 30px 40px 140px;
        ul{
          li{
            border-bottom: 1px solid $color-grey3;
            padding: 50px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            figure{
              width: 167px;
              margin-top: 10px;
              img{
                width: 100%;
                height: auto;
              }
            }
            .txtBox{
              width: calc(100% - 217px);
              .bookTitle{
                margin-bottom: 14px;
                span{
                  font-size: 20px;
                  font-weight: $bold;
                  color: $color-brown1;
                }
              }
              p{
                line-height: 2.0;
                margin-bottom: 20px;
              }
              .align-right{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .contBox03{
      padding-bottom: 180px;
      .contSubBox01{
        padding: 40px 40px 46px;
        ul{
          li{
            margin-bottom: 6px;
          }
        }
      }
      .contSubBox02{
        padding: 0 40px;
        ul{
          &.listBar{
            margin-bottom: 22px;
          }
          .dotTypeBar{
            margin-left: 23px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  /*
  後藤孝典が語る ヘッダー・メインイメージ
  -------------------------------------*/
  #PageGototakanori {
    #Header #ContBoxHeader #HeaderMenu {
      .subNav,
      .mainNav{
        display: none;
      }
    }
    #MainImg{
      height: 550px;
      #MainImgInner{
        position: relative;
        height: 100%;
        .imgBox{
          height: 100%;
          width: 54.2%;
          position: absolute;
          top: 0;
          right: 0;
        }
        h2{
          position: absolute;
          top: 50%;
          transform: translate(0 ,-85px);
          margin: 0 auto;
          padding-top: 40px;
          &.titleTypeMainVisu[data-subtitle]::after{
            top: 1px;
            padding-left: 49px;
          }
          &::before{
            left: 130px;
            top: 17px;
          }
        }
        p{
          position: absolute;
          top: 50%;
          transform: translate(0 ,35px);
          font-size: 14px;
        }
      }
    }
  }

  /*
  後藤孝典が語る
  -------------------------------------*/
  #PageGototakanori.pageIndex {
    .contBox01{
      padding: 140px 0 255px;
      .contSubBox01{
        width: 1000px;
        margin: 0 auto;
        .post{
          margin-bottom: 95px;
          .postCont{
            padding: 90px 100px 80px;
            margin-bottom: 50px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .date{
              margin-bottom: 4px;
            }
            .title{
              font-size: 20px;
              margin-bottom: 35px;
            }
            .entryBody{
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

  /*
  後藤孝典が語る（記事詳細）
  -------------------------------------*/
  #PageGototakanori.pageEntry {
    .contBox01{
      padding: 140px 0 220px;
      .contSubBox01{
        width: 1000px;
        margin: 0 auto 67px;
        padding: 90px 100px 110px;
        .date{
          margin-bottom: 14px;
        }
        .titleTypeBasic{
          padding: 20px 0;
          margin-bottom: 50px;
        }
      }
    }
  }

  /*
  お知らせブログ（カテゴリ）
  -------------------------------------*/
  #PageBlog.pageCategory {
    .contBox01{
      padding-bottom: 260px;
      .entryType01{
        padding: 115px 40px 75px;
        dl{
          a{
            padding: 36px 0;
          }
          .date{
            width: 180px;
          }
          .title{
            width: calc(100% - 180px);
            padding: 0 23px;
          }
        }
      }
      .entryType02{
        width: 1000px;
        margin: 0 auto;
        padding: 115px 40px 75px;
        .post{
          margin-bottom: 95px;
          .postCont{
            padding: 90px 100px 80px;
            margin-bottom: 50px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .date{
              margin-bottom: 4px;
            }
            .title{
              font-size: 20px;
              margin-bottom: 35px;
            }
            .entryBody{
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

  /*
  お知らせブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry {
    .contBox01{
      padding: 115px 0 225px;
      .contSubBox01{
        width: 1000px;
        margin: 0 auto 65px;
        padding: 90px 100px 120px;
        .date{
          margin-bottom: 12px;
        }
        .titleTypeBasic{
          padding: 20px 0;
          margin-bottom: 20px;
        }
      }
    }
  }

    /*
    お問い合わせ（共通）
    -------------------------------------*/
    #PageContact {
        #Main{
            .innerBasic{
                padding: 114px 0 200px;
                width: 1000px;
            }
            .formBox{
                .tableTypeForm{
                    margin-bottom: 51px;
                    tbody{
                        tr{
                            th{
                                vertical-align: top;
                                padding-top: 16px;
                                padding-left: 65px;
                                width: 319px;
                                &::before{
                                    top: 18px;
                                    padding: 5px;
                                    width: 50px;
                                    height: 24px;
                                    font-size: 14px;
                                }
                            }
                            td{
                                padding-bottom: 50px;
                                input[type="text"], 
                                input[type="email"], 
                                input[type="tel"], 
                                textarea{
                                    padding: 17px 17px;
                                }
                                textarea{
                                    height: 300px;
                                }
                            }
                            &.noAttention{
                                th{
                                    &::before{
                                        padding: 4px;
                                    }
                                }
                            }
                            &.name, &.name_kana, &.tel, &.mailaddress{
                                td{
                                    input[type="text"], 
                                    input[type="email"], 
                                    input[type="radio"], 
                                    input[type="checkbox"], 
                                    input[type="tel"]{
                                        width: 500px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .listTypeButton{
                position: relative;
                li{
                    display: inline-block;
                    &.submit, &.back{
                        .btnTypeBasic{
                            padding-bottom: 0;
                            width: 318px;
                            height: 79px;
                            //letter-spacing: -2px;
                            font-size: 16px;
                            transition: all .3s;
                            &:hover{
                                background-color: #574235;
                                transition: all .3s;
                            }
                        }
                    }
                    &.back{
                        .btnTypeBasic{
                            padding-top: 27px;
                        }
                    }
                }
            }
            .txtTypeCenter{
                input{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    /*
    お問い合わせ（入力画面）
    -------------------------------------*/
    #PageContact.pageIndex {
    }

    /*
    お問い合わせ（確認画面）
    -------------------------------------*/
    #PageContact.pageConfirm {
        #Main{
            .formBox{
                .tableTypeForm{
                    margin-bottom: 51px;
                    tbody{
                        tr{
                            &:nth-of-type(4){
                                th{
                                    &::before{
                                        padding: 4px;
                                    }
                                }
                            }
                            th{
                                padding-bottom: 16px;
                            }
                            td{
                                padding-top: 16px;
                                padding-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    /*
    お問い合わせ（完了画面）
    -------------------------------------*/
    #PageContact.pageThanks {
    }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #Main{
      .innerBasic{
        width: 1000px;
        margin: 0 auto;
      }
      .contBox01{
        padding-top: 105px;
        padding-bottom: 190px;
        .contSubBox01{
          margin-bottom: 95px;
        }
        .contSubBox02{
          h3{
            font-size: 20px;
            padding: 14px 0;
            margin-top: 70px;
          }
          p{
            margin-top: 32px;
          }
          ul{
            margin-top: 32px;
            li{
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg{
      height: 500px;
      padding-left: 15px;
      padding-bottom: 15px;
      &::after{
        bottom: 0;
        height: 420px;
        width: calc(100% - 15px);
      }
      #MainImgInner{
        background: url(../img/contents/top/main_visu_sp.jpg) no-repeat center center;
        background-size: cover;
        .inner{
          height: 100%;
        }
        h2{
          font-size: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50% ,calc(-50% - 30px));
          b{
            &::after{
              height: 55px;
              width: 310px;
              top: -3px;
              left: -22px;
            }
            em{
              white-space: nowrap;
            }
          }
        }
        p{
          font-size: 16px;
          white-space: nowrap;
          &.disp_pc{
            display: none;
          }
          &:nth-of-type(2){
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% - 15px), 18px);
            b{
              &::after{
                //content:"";
                height: 41px;
                width: 262px;
                top: -10px;
                left: -17px;
              }
            }
          }
          &:nth-of-type(3){
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% + 10px), 53px);
            b{
              &::after{
                height: 41px;
                width: 267px;
                top: -10px;
                left: -12px;
              }
            }
          }
        }
      }
    }
    #ContBox01{
      padding: 60px 0;
      &::after{
        top: -15px;
        width: 15px;
        height: 15px;
      }
      .contSubBox01{
        overflow: hidden;
        padding: 0 15px;
        .titleTypeBasic{
          font-size: 18px;
          padding: 15px 0;
          margin-bottom: 30px;
        }
        .entryBox{
          margin-bottom: 53px;
          img{
            width: 167px!important;
            height: auto!important;
            margin: 0 auto 20px!important;
            display: block!important;
            float: none!important;
          }
          a{
            word-break: break-all;
          }
        }
      }
    }
    #ContBox02{
      padding: 38px 0 40px;
      &::after{
        height: calc(100% - 280px);
        top: 280px;
      }
      h3{
        margin-bottom: 35px;
      }
      .contSubBox01{
        padding: 0 15px;
        ul{
          margin-bottom: 50px;
          li{
            margin-bottom: 30px;
            a{
              figure{
                &::after{
                  width: 45px;
                  height: 45px;
                  background-size: 9px auto;
                }
                img{
                  height: auto;
                }
              }
              dl{
                padding: 25px 20px;
                dt{
                  font-size: 20px;
                  margin-bottom: 6px;
                }
                dd{
                  line-height: 1.8;
                }
              }
            }
          }
        }
      }
    }
    #ContBox03{
      padding: 50px 0 0;
      h3{
        font-size: 26px;
        margin-bottom: 25px;
      }
      .contSubBox01{
        .bgImg{
          height: 195px;
        }
        .blogList{
          .list{
            a{
              small{
              }
            }
          }
          .list01{
            a{
              font-size: 20px;
              padding: 40px 90px 40px 60px;
              background-size: 32px auto;
              background-position: center right 58px;
            }
          }
          .list02{
            a{
              font-size: 16px;
              padding: 20px 20px 20px 15px;
              background-size: 26px auto;
              &:nth-of-type(1){
                background-position: bottom 18px right 40px;
              }
              &:nth-of-type(2){
                background-position: bottom 18px right 10px;
              }
              small{
                font-size: 12px;
                line-height: 2.0;
              }
            }
          }
          .list03{
            a{
              font-size: 18px;
              padding: 15px 45px 15px 15px;
              background-size: 26px auto;
              background-position: center right 15px;
              figure{
                width: 48px;
                margin-right: 30px;
              }
              span{
                padding: 20px 0;
                display: block;
                width: calc(100% - 78px);
              }
            }
          }
        }
      }
    }
    #ContBox04{
      padding: 63px 15px 0;
      .contSubBox01{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h3{
          margin-top: 10px;
          margin-bottom: 25px;
          &.titleTypeMain[data-subtitle]::after{
            top: -20px;
          }
        }
        .btnTypeBasic{
          font-size: 14px;
          width: 130px;
          height: 45px;
          margin: 0;
          padding: 10px 15px 10px 15px;
          span{
            padding-right: 25px;
            &::after{
              background-size: 20px;
              right: -5px;
            }
          }
        }
      }
      .contSubBox02{
        .post{
          li{
            padding: 20px 0;
            .date{
              margin-bottom: 3px;
            }
            .postCont{
              h4{
                font-size: 18px;
                margin-bottom: 0;
              }
              p{
                line-height: 1.8;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
    #ContBox05{
      padding: 55px 15px 85px;
      .contSubBox01{
        margin-bottom: 20px;
        h3{
          margin-top: 20px;
          margin-bottom: 23px;
        }
        img{
          width: 100%;
          height: auto;
          margin-bottom: 4px;
        }
        &>p{
          line-height: 1.8;
        }
        .address{
          margin-top: 16px;
          p{
            line-height: 1.5;
            &:nth-of-type(1){
              margin-bottom: 18px;
            }
            &:nth-of-type(2){
              line-height: 1.8;
            }
          }
        }
      }
      .contSubBox02{
        iframe{
          height: 220px;
        }
      }
    }
  }

  //下層メインイメージ
  #PageFeature,
  #PageBusiness,
  #PageBunkatsu,
  #PageAdr,
  #PageOffice,
  #PageBooks,
  #PageBlog,
  #PageContact,
  #PagePolicy{
    #MainImg{
      height: 240px;
      padding-top: 60px;
      h2{
        left: 15px;
      }
    }
  }

  /*
  お知らせブログメインイメージ調整
  -------------------------------------*/
  #PageBlog{
    #MainImg{
      h2{
        transform: translate(0 ,calc(50%  - 25px));
        line-height: 1.2;
      }
    }
  }

  /*
  当法律事務所の強み
  -------------------------------------*/
  #PageFeature.pageIndex {
    .contBox01{
      padding: 52px 15px 50px;
      .contSubBox01{
        .wrap{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 55px;
        }
        .wrap.disp_pc{ display: none }
        ul{
          border: 1px solid $color-brown1;
          padding: 26px 15px;
          margin-bottom: 25px;
          position: relative;
          &::after{
            content: "";
            display: block;
            position: absolute;
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
            width: 22px;
            height: 34px;
            background: url(../img/contents/feature/arrow01_sp.png) no-repeat center center;
            background-size: 22px auto;
          }
          li{
            font-weight: $bold;
            color: $color-brown1;
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 7px;
            &::after{
              top: 9px;
            }
          }
        }
        .infoBox{
          margin-top: 20px;
          margin-bottom: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          img{
            width: 90px;
            height: auto;
            margin-right: 15px;
            display: block;
          }
          p{
            width: calc(100% - 105px);
          }
        }
        .txtBox{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          p{
            width: calc(100% - 95px);
            margin-right: 30px;
          }
          img{
            width: 65px;
            height: auto;
            display: block;
          }
        }
        &>p{
          line-height: 1.8;
        }
      }
    }
    .contBox02{
      padding: 60px 15px 40px;
      .contSubBox01{
        ul{
          li{
            padding: 40px 20px 35px;
            margin-bottom: 40px;
            text-align: justify;
            &::after{
              width: 45px;
              height: 45px;
              line-height: 45px;
              font-size: 21px;
              top: -20px;
              left: 20px
            }
            &:nth-of-type(1)::after{
              content: "1";
            }
            &:nth-of-type(2)::after{
              content: "2";
            }
            &:nth-of-type(3)::after{
              content: "3";
            }
            &:nth-of-type(4){
              padding-top: 75px;
              &::after{
                content: "4";
              }
              dl {
                dt{
                  &[data-subtitle]{
                    &::after{
                      top: -30px;
                    }
                  }
                }
                dd p{
                  margin-bottom: 20px;
                  &:last-of-type{
                    margin-bottom: 0;
                  }
                  .redTxt{
                    text-decoration: underline;
                  }
                }
              }
            }
            dl{
              dt{
                font-size: 20px;
                margin-bottom: 18px;
              }
              dd{
                line-height: 1.8;
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }

  /*
  取扱業務
  -------------------------------------*/
  #PageBusiness.pageIndex {
    #Main{
      .titleTypeBasic{
        margin: 10px 0 25px;
      }
      .contBox01{
        padding: 50px 15px 85px;
      }
    }
  }

  /*
  会社分割
  -------------------------------------*/
  #PageBunkatsu.pageIndex {
    .titleTypeBg{
      padding: 21px 15px;
      font-size: 18px;
      margin-bottom: 42px;
    }
    .contBox01{
      padding: 50px 15px 25px;
      .contSubBox01{
        figure{
          img{
          }
          figcaption{
            text-align: left;
            margin-top: 12px;
            margin-bottom: 28px;
          }
        }
        .txtBox{
          p{
            margin-bottom: 26px;
            line-height: 1.8;
          }
        }
      }
    }
    .contBox02,
    .contBox03{
      .contSubBox01{
        .txtBox{
          margin-bottom: 50px;
          .wrap{
            padding: 0 15px;
            margin-bottom: 32px;
          }
          .titleTypeBasic{
            padding: 9px 0;
            margin-bottom: 20px;
          }
          p{
            margin-bottom: 12px;
          }
          ul{
            li{
              margin-bottom: 2px;
              &.dotTypeBasic{
                &::after{
                  top: 12px;
                }
              }
            }
          }
        }
      }
    }
    .contBox04{
      .titleTypeBg{
        margin-bottom: 30px;
      }
      .contSubBox01{
        margin-bottom: 70px;
        img{
          width: 175px;
          height: auto;
          display: block;
          margin: 0 auto 20px;
        }
        .txtBox{
          padding: 0 15px;
          p{
            line-height: 1.8;
            margin-bottom: 25px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .contBox05{
      padding: 0 15px 100px;
      &::after{
        height: calc(100% - 115px);
        top: 115px;
      }
      .contSubBox01{
        padding: 35px 20px 40px;
        margin-bottom: 40px;
        .txtBox{
          padding: 27px 15px 24px;
          margin-bottom: 32px;
          h5{
            font-size: 18px;
            margin-bottom: 6px;
          }
          p{
            line-height: 1.8;
            text-align: justify;
          }
        }
        .txtList{
          li{
            width: calc(calc(100% - 20px) / 3);
            padding: 23px 0;
            font-size: 16px;
            &:nth-of-type(1){
              padding-top: 35px;
            }
            &::after{
              background-size: 32px auto;
              width: 32px;
              height: 15px;
              top: -22px;
            }
          }
        }
      }
      .contSubBox02{
        padding: 30px 20px;
        &>p{
          font-size: 18px;
          margin-bottom: 45px;
          &::after{
            height: 30px;
            bottom: -40px;
          }
        }
        p{
          line-height: 1.8;
          text-align: center;
        }
        .box01{
          width: 100%;
          padding-bottom: 22px;
          .tel{
            font-size: 32px;
            a{
            }
          }
          .fax{
            margin-top: -10px;
          }
        }
        .box02{
          .mail{
            margin-top: 10px;
            a{
            }
          }
        }
      }
    }
  }

  /*
  日本企業再建研究会
  -------------------------------------*/
  #PageAdr.pageIndex {
    .contBox01{
      padding: 50px 15px 50px;
      .contSubBox01{
        dl{
          padding: 34px 10px 35px;
          margin-bottom: 40px;
          dt{
            font-size: 20px;
            letter-spacing: 0em;
            margin-bottom: 21px;
          }
          dd{
            font-size: 12px;
            line-height: 2.0;
          }
        }
        figure{
          margin-bottom: 50px;
          a{
            margin-bottom: 20px;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
      }
      .contSubBox02{
        p{
          line-height: 1.75;
          margin-bottom: 30px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
    }
    .contBox02{
      padding: 0 15px 95px;
      .contSubBox01{
        ul{
          li{
            width: calc(calc(100% - 15px) / 3);
            margin-bottom: 6px;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  /*
  事務所案内
  -------------------------------------*/
  #PageOffice.pageIndex {
    .titleTypeBg{
      padding: 22px 15px;
      font-size: 18px;
      margin-bottom: 30px;
    }
    .titleTypeBasic{
      padding: 10px 0;
      margin-bottom: 20px;
      &+p{
        margin-bottom: 12px;
      }
    }
    .titleTypeBorder{
      font-size: 18px;
      padding: 12px 27px;
      margin-bottom: 30px;
      span{
        font-size: 14px;
        position: relative;
        top: -2px;
      }
    }
    .contBox01{
      ul{
        padding: 53px 15px 60px;
        li{
          margin-bottom: 7px;
          &:last-of-type{
            margin-bottom: 0;
          }
          a{
            text-align: left;
            padding-right: 16px;
            background-color: $color-grey2;
            background-size: 12px auto;
            background-position: right 15px center;
            display: block;
            padding: 12px 20px;
          }
        }
      }
    }
    .contBox02{
      .contSubBox01{
        padding: 0 15px 20px;
        img{
          width: 175px;
          height: auto;
          margin: 0 auto;
          display: block;
        }
        .txtBox{
          padding-top: 20px;
          p{
            line-height: 1.8;
            margin-bottom: 30px;
          }
          .align-right{
            font-size: 16px;
            span{
              font-size: 14px;
            }
          }
        }
      }
    }
    .contBox03{
      .titleTypeBg{
        margin-bottom: 40px;
      }
      .contSubBox01,
      .contSubBox02{
        padding: 0 15px;
        .txtBox{
          ul{
            margin-bottom: 30px;
            li{
              margin-bottom: 2px;
              &:last-of-type{
                margin-bottom: 0;
              }
              &.dotTypeBasic::after{
                top: 13px;
              }
            }
          }
        }
      }
      .contSubBox01{
        margin-bottom: 33px;
        .txtBox{
          .wrap{
          }
        }
      }
      .contSubBox02{
        margin-bottom: 30px;
      }
      .contSubBox03{
        background-color: $color-grey2;
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 34px 16px;
        ul{
          margin-bottom: 15px;
          &:last-of-type{
            margin-bottom: 0;
          }
          li{
            margin-bottom: 20px;
            &:last-of-type{
              margin-bottom: 0;
            }
            dl{
              dt{
                font-size: 16px;
                line-height: 1.5;
              }
              dd{
                padding-left: 25px;
                line-height: 1.8;
              }
            }
          }
        }
      }
      .contSubBox04{
        padding: 40px 15px 55px;
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            width: calc(calc(100% - 20px) / 4);
            margin-bottom: 5px;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    .contBox04{
      .titleTypeBg{
        margin-bottom: 40px;
      }
      .contSubBox{
        padding: 0 15px;
        .wrap{
          margin-bottom: 40px;
        }
        img{
          width: 175px;
          height: auto;
          margin: 0 auto 30px;
          display: block;
        }
        .tableWrap{
        }
        table{
          tr{
            th{
              width: 32%;
              padding: 12px 15px;
              font-size: 14px;
            }
            td{
              width: 68%;
              padding: 12px 30px 12px 0;
              font-size: 16px;
            }
          }
        }
      }
      .contSubBox02{
        margin-bottom: 60px;
      }
    }
    .contBox05{
      &::after{
        height: calc(100% - 335px);
        top: 335px;
      }
      .titleTypeBg{
        margin-bottom: 35px;
      }
      .contSubBox01{
        padding: 0 15px;
        h4{
          font-size: 18px;
          margin-bottom: 5px;
        }
        &>p{
          font-size: 14px;
          margin-bottom: 22px;
          line-height: 1.8;
        }
        .txtBox{
          padding: 20px 0 35px;
          .box01{
            padding: 0 15px;
            width: calc(100% - 30px);
            margin: 0 auto;
            padding-bottom: 22px;
            .tel{
              font-size: 32px;
              a{
              }
            }
            .fax{
              margin-top: -12px;
            }
          }
          .box02{
            .mail{
              margin-top: 3px;
              a{
              }
            }
          }
        }
      }
      .contSubBox02{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 15px 30px;
        .box{
          width: calc(50% - 8px);
          .txtBox{
            padding: 23px 10px;
            h4, p{
            }
            h4{
              font-size: 18px;

            }
            p{
              font-size: 14px;
              line-height: 1.8;
            }
          }
        }
        .box01{
          .txtBox{
            &::after{
              background: url(../img/contents/office/arrow02_sp.png) no-repeat center center;
              background-size: 31px auto;
              width: 31px;
              height: 130px;
              bottom: -131px;
            }
          }
        }
        .box02{
          .txtBox{
            &::after{
              background: url(../img/contents/office/arrow03.png) no-repeat center center;
              background-size: 31px auto;
              width: 31px;
              height: 16px;
              bottom: -24px;
            }
            &:nth-of-type(1){
              margin-bottom: 30px;
              h4{
                letter-spacing: 0em;
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 33px 20px 40px;
        background-color: #fff;
        border: 1px solid $color-brown1;
        width: calc(100% - 30px);
        margin: 0 auto;
        &+p{
          font-size: 14px;
          margin-top: 10px;
          padding: 0 15px;
          line-height: 1.8;
        }
        .txtBoxHead{
          padding-bottom: 37px;
          p{
            text-align: center;
            margin-bottom: 12px;
            line-height: 1.8;
          }
          ul{
            margin-bottom: 12px;
            li{
              background-color: $color-brown2;
              color: #fff;
              text-align: center;
              padding: 8px 0;
              margin-bottom: 5px;
              &:last-of-type{
                margin-right: 0;
              }
            }
          }
        }
        .txtBoxBody{
          .titleTypeBar::after{
            top: -24px;
          }
          .txtBox{
            padding: 27px 15px 24px;
            margin-bottom: 32px;
            h5{
              font-size: 18px;
              margin-bottom: 6px;
            }
            p{
              line-height: 1.8;
              text-align: justify;
            }
          }
          .txtList{
            li{
              width: calc(calc(100% - 20px) / 3);
              padding: 23px 0;
              font-size: 16px;
              &:nth-of-type(1){
                padding-top: 35px;
              }
              &::after{
                background-size: 32px auto;
                width: 32px;
                height: 15px;
                top: -22px;
              }
            }
          }
        }
      }
      .contSubBox04{
        padding: 53px 0 50px;
        .titleTypeBgYe{
          font-size: 18px;
          padding: 16px 15px;
          margin-bottom: 23px;
        }
        &>ul{
          &>li{
            p{
              margin-bottom: 25px;
              padding: 0 15px;
              line-height: 1.8;
              &:last-of-type{
                margin-bottom: 45px;
              }
            }
            &:last-of-type{
              p:last-of-type{
                margin-bottom: 0;
              }
            }
            &>ul{
              margin-bottom: 30px;
              padding: 0 15px;
              &>li{
                margin-bottom: 8px;
                line-height: 1.8;
                &.dotTypeBasic::after{
                  top: 12px;
                }
                &:last-of-type{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .contBox06{
      padding: 0 0 70px;
      .titleTypeBg{
        margin-bottom: 20px;
      }
      .contSubBox01{
        padding: 0 15px;
        p{
          margin-bottom: 25px;
          line-height: 1.8;
        }
        iframe{
          width: 100%;
          height: 220px;
        }
        .linkTypeBlank{
          width: 150px;
          margin-bottom: 32px;
        }
        ul{
          margin-bottom: 15px;
          &+p{
            font-size: 14px;
          }
          li{
            line-height: 1.8;
            &.dotTypeBasic::after{
              top: 13px;
            }
          }
        }
      }
    }
  }

  /*
  著書・論文
  -------------------------------------*/
  #PageBooks.pageIndex {
    .titleTypeBg{
      font-size: 18px;
      padding: 22px 15px;
    }
    .titleTypeBasic{
      font-size: 18px;
      padding: 11px 0;
      margin-bottom: 20px;
    }
    .contBox01{
      padding: 55px 15px 60px;
      ul{
        justify-content: space-between;
        li{
          background-color: $color-grey2;
          width: calc(50% - 3px);
          &:last-of-type{
            margin-right: 0;
          }
          a{
            padding: 12px 20px;
            background-size: 12px auto;
            background-position: right 15px center;
          }
        }
      }
    }
    .contBox02{
      .contSubBox01{
        padding: 10px 15px 60px;
        ul{
          li{
            border-bottom: 1px solid $color-grey3;
            padding: 40px 0 20px;
            figure{
              width: 167px;
              margin: 0 auto 20px;
              img{
                width: 100%;
                height: auto;
              }
            }
            .txtBox{
              .bookTitle{
                margin-bottom: 14px;
                span{
                  font-size: 18px;
                  font-weight: $bold;
                  color: $color-brown1;
                }
              }
              p{
                line-height: 1.8;
                margin-bottom: 20px;
              }
              .align-right{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .contBox03{
      padding-bottom: 80px;
      .contSubBox01{
        padding: 30px 15px 40px;
        ul{
          li{
            line-height: 1.8;
            margin-bottom: 4px;
            &.dotTypeBasic::after{
              top: 10px;
            }
          }
        }
      }
      .contSubBox02{
        padding: 0 15px;
        ul{
          &.listBar{
            margin-bottom: 12px;
          }
          li{
            line-height: 1.8;
          }
          .dotTypeBasic{
            margin-bottom: 8px;
            &::after{
              top: 12px;
            }
          }
          .dotTypeBar{
            margin-left: 23px;
            margin-bottom: 6px;
            &::after{
              top: 15px;
            }
          }
        }
      }
    }
  }

  /*
  後藤孝典が語る ヘッダー・メインイメージ
  -------------------------------------*/
  #PageGototakanori {
    #MenuButton{
      display: none;
    }
    #MainImg{
      height: 405px;
      #MainImgInner{
        position: relative;
        height: 100%;
        .imgBox{
          height: 194px;
        }
        .innerBasic{
          padding: 18px 15px 22px;
        }
        h2{
          margin: 0 auto 3px;
          padding-top: 26px;
          text-align: left;
          font-size: 26px;
          background-size: 24px auto;
          &.titleTypeMainVisu[data-subtitle]::after{
            top: 0;
            padding-left: 35px;
          }
          &::before{
            left: 94px;
            top: 13px;
            width: 35px;
          }
        }
        p{
          font-size: 10px;
        }
      }
    }
  }

  /*
  後藤孝典が語る
  -------------------------------------*/
  #PageGototakanori.pageIndex {
    .contBox01{
      padding: 30px 15px 210px;
      .contSubBox01{
        margin: 0 auto;
        .post{
          margin-bottom: 55px;
          .postCont{
            padding: 40px 20px 50px;
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .date{
              margin-bottom: 0;
            }
            .title{
              font-size: 18px;
              margin-bottom: 15px;
            }
            .entryBody{
              margin-bottom: 40px;
              p{
                line-height: 1.8;
              }
            }
          }
        }
      }
    }
  }

  /*
  後藤孝典が語る（記事詳細）
  -------------------------------------*/
  #PageGototakanori.pageEntry {
    .contBox01{
      padding: 30px 15px 130px;
      .contSubBox01{
        margin: 0 auto 67px;
        padding: 40px 20px 50px;
        .date{
          margin-bottom: 10px;
        }
        .titleTypeBasic{
          padding: 15px 0;
          margin-bottom: 50px;
        }
      }
    }
  }

  /*
  お知らせブログ（カテゴリ）
  -------------------------------------*/
  #PageBlog.pageCategory {
    .contBox01{
      padding-bottom: 210px;
      .entryType01{
        padding: 50px 0 35px;
        dl{
          a{
            padding: 25px 0;
          }
          .date{
            width: 115px;
            padding: 0 15px;
          }
          .title{
            width: calc(100% - 115px);
            padding: 0 20px 0 0;
          }
        }
      }
      .entryType02{
        padding: 50px 0 35px;
        width: calc(100% - 30px);
        margin: 0 auto;
        overflow: hidden;
        .post{
          margin-bottom: 55px;
          .postCont{
            padding: 40px 20px 50px;
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .date{
              margin-bottom: 0;
            }
            .title{
              font-size: 18px;
              margin-bottom: 15px;
            }
            .entryBody{
              margin-bottom: 40px;
              p{
                line-height: 1.8;
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry {
    .contBox01{
      padding: 52px 15px 150px;
      .contSubBox01{
        padding: 38px 20px 55px;
        margin: 0 auto 65px;
        overflow: hidden;
        .date{
          margin-bottom: 12px;
        }
        .titleTypeBasic{
          padding: 12px 0;
          margin-bottom: 20px;
        }
      }
    }
  }

    /*
    お問い合わせ（共通）
    -------------------------------------*/
    #PageContact {
        #Main{
            .innerBasic{
                padding: 49px 15px 100px;
            }
            .formBox{
                .tableTypeForm{
                    display: block;
                    margin-bottom: 60px;
                    tbody, tr, th, td{
                        display: block;
                    }
                    tbody{
                        tr{
                            margin-bottom: 27px;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            th{
                                padding-bottom: 14px;
                                padding-left: 48px;
                                &::before{
                                    top: 3px;
                                    padding: 4px;
                                    width: 40px;
                                    height: 21px;
                                    font-size: 12px;
                                }
                            }
                            td{
                                input[type="text"], 
                                input[type="email"], 
                                input[type="tel"], 
                                textarea{
                                    padding: 12px 12px;
                                }
                                textarea{
                                    height: 210px;
                                }
                            }
                        }
                    }
                }
            }
            .listTypeButton{
                li{
                    &.submit, &.back{
                        .btnTypeBasic{
                            margin-top: 0;
                            margin-right: auto;
                            padding-bottom: 0;
                            width: 300px;
                            height: 70px;
                            letter-spacing: 0;
                            font-size: 16px;
                        }
                    }
                    &.back{
                    }
                }
            }
        }
    }

    /*
    お問い合わせ（入力画面）
    -------------------------------------*/
    #PageContact.pageIndex {
    }

    /*
    お問い合わせ（確認画面）
    -------------------------------------*/
    #PageContact.pageConfirm {
        #Main{
            .formBox{
                .tableTypeForm{
                    margin-bottom: 51px;
                    tbody{
                        tr{
                            margin-bottom: 35px;
                            &:nth-of-type(4){
                                th{
                                    &::before{
                                        padding: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*
    お問い合わせ（完了画面）
    -------------------------------------*/
    #PageContact.pageThanks {
        #Main{
            .listTypeButton{
                li{
                    &.back{
                        .btnTypeBasic{
                            padding-top: 21px;
                        }
                    }
                }
            }
        }
    }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #Main{
      .innerBasic{
      }
      .contBox01{
        padding: 44px 15px 95px;
        p{
          line-height: 1.8;
        }
        .contSubBox01{
          margin-bottom: 53px;
        }
        .contSubBox02{
          .titleTypeBasic{
            font-size: 18px;
            padding: 11px 0;
            margin-top: 50px;
          }
          p{
            margin-top: 22px;
          }
          ul{
            margin-top: 23px;
            li{
            }
          }
        }
      }
    }
  }


}


@media print,
screen and (max-width: 370px) {
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList {
    .list01 a{
      font-size: 18px;
    }
    .list02 a{
      font-size: 14px;
      padding-right: 40px;
      &:nth-of-type(1){
        background-position: bottom 18px right 10px;
      }
      small{
        font-size: 10px;
      }
    }
    .list03 a{
      font-size: 16px;
    }
  }
}