@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&family=Noto+Serif+JP:wght@400;500;600;700&display=swap");
/*
トップページ
-------------------------------------*/
#Page.pageIndex #MainImg {
  position: relative; }
  #Page.pageIndex #MainImg::after {
    content: "";
    display: block;
    background-color: #3b2e26;
    position: absolute;
    left: 0;
    z-index: -1; }
  #Page.pageIndex #MainImg #MainImgInner {
    height: 100%; }
    #Page.pageIndex #MainImg #MainImgInner .inner {
      position: relative; }
    #Page.pageIndex #MainImg #MainImgInner h2, #Page.pageIndex #MainImg #MainImgInner p {
      font-family: "Noto Serif JP", serif;
      font-weight: 600;
      color: #fff;
      display: inline-block;
      position: absolute; }
      #Page.pageIndex #MainImg #MainImgInner h2 b, #Page.pageIndex #MainImg #MainImgInner p b {
        position: relative; }
        #Page.pageIndex #MainImg #MainImgInner h2 b::after, #Page.pageIndex #MainImg #MainImgInner p b::after {
          content: "";
          position: absolute;
          transform: skewX(-15deg);
          background-color: #3b2e26;
          z-index: 1; }
        #Page.pageIndex #MainImg #MainImgInner h2 b em, #Page.pageIndex #MainImg #MainImgInner p b em {
          position: relative;
          z-index: 2; }
    #Page.pageIndex #MainImg #MainImgInner h2 {
      text-align: left;
      position: absolute; }
    #Page.pageIndex #MainImg #MainImgInner p {
      position: absolute; }
      #Page.pageIndex #MainImg #MainImgInner p span {
        color: #fbc100; }
#Page.pageIndex #ContBox01 {
  background-color: #f5f5f5;
  position: relative; }
  #Page.pageIndex #ContBox01::after {
    content: "";
    display: block;
    background-color: #f5f5f5;
    position: absolute;
    right: 0;
    z-index: -1; }
#Page.pageIndex #ContBox02 {
  overflow: hidden;
  position: relative; }
  #Page.pageIndex #ContBox02::after {
    content: "";
    display: block;
    background-color: #f5f5f5;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1; }
  #Page.pageIndex #ContBox02 .contSubBox01 ul li a {
    text-decoration: none; }
    #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure {
      position: relative; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60px;
        height: 60px;
        background-color: #3b2e26;
        background-image: url(../img/contents/top/arrow01.png);
        background-size: 18px auto;
        background-repeat: no-repeat;
        background-position: center center; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure img {
        width: 100%; }
    #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl {
      background-color: #fff; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dt {
        font-family: "Noto Serif JP", serif;
        font-weight: 600;
        color: #c7901a; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dd {
        color: #222; }
#Page.pageIndex #ContBox03 .contSubBox01 .bgImg {
  background: url(../img/contents/top/img_05.jpg) no-repeat center center;
  background-size: cover; }
#Page.pageIndex #ContBox03 .contSubBox01 .blogList .list {
  font-family: "Noto Serif JP", serif; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list a {
    color: #fff;
    display: block;
    text-decoration: none;
    text-align: left;
    background-image: url(../img/contents/top/icon_btn_top.png);
    background-repeat: no-repeat; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list a small {
      display: block; }
#Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a {
  font-weight: 600; }
#Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 {
  display: flex;
  flex-wrap: wrap; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a {
    width: 50%;
    border-top: 1px solid #625851; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a:nth-of-type(1) {
      border-right: 1px solid #625851; }
#Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a, #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a {
  background-color: #3b2e26; }
#Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a {
  background-color: #cc9b30;
  display: flex;
  flex-wrap: wrap; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a figure {
    width: 70px;
    margin-right: 50px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a figure img {
      width: 100%;
      height: auto !important; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a span {
    width: calc(100% - 120px); }
#Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic {
  text-align: left; }
#Page.pageIndex #ContBox04 .contSubBox02 .post li {
  border-top: 1px solid #d0d0d0; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li:last-of-type {
    border-bottom: 1px solid #d0d0d0; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li .date {
    color: #cc9b30; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont a {
    color: #222;
    text-decoration: none; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont h4 {
    font-weight: 700;
    line-height: 1.8; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont p {
    color: #888888; }
  #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont .linkMore {
    color: #286da7;
    text-decoration: underline; }
#Page.pageIndex #ContBox05 iframe {
  width: 100%; }
#Page.pageIndex #ContBox05 .contSubBox01 h4 {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/contents/top/address_logo.png);
  text-indent: -9999px; }
#Page.pageIndex #ContBox05 .contSubBox01 > p {
  font-size: 14px; }

#PageFeature #MainImg,
#PageBusiness #MainImg,
#PageBunkatsu #MainImg,
#PageAdr #MainImg,
#PageOffice #MainImg,
#PageBooks #MainImg,
#PageBlog #MainImg,
#PageContact #MainImg,
#PagePolicy #MainImg {
  background-color: #3b2e26;
  position: relative; }
  #PageFeature #MainImg h2,
  #PageBusiness #MainImg h2,
  #PageBunkatsu #MainImg h2,
  #PageAdr #MainImg h2,
  #PageOffice #MainImg h2,
  #PageBooks #MainImg h2,
  #PageBlog #MainImg h2,
  #PageContact #MainImg h2,
  #PagePolicy #MainImg h2 {
    position: absolute;
    top: 50%;
    transform: translate(0, 50%);
    text-align: left; }

/*
当法律事務所の強み
-------------------------------------*/
#PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox ul {
  border: 1px solid #552b11; }
  #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox ul li {
    font-weight: 700;
    color: #552b11; }
#PageFeature.pageIndex .contBox02 {
  background-color: #f5f5f5; }
  #PageFeature.pageIndex .contBox02 .contSubBox01 ul li {
    background-color: #fff;
    position: relative; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 ul li::after {
      content: "";
      display: block;
      background-color: #c7901a;
      color: #fff;
      font-family: "Noto Serif JP", serif;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dt {
      color: #c7901a;
      font-family: "Noto Serif JP", serif;
      font-weight: 600; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dd a {
      color: #da4a4a; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dt {
      position: relative; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dt[data-subtitle]::after {
        content: attr(data-subtitle);
        font-size: 14px;
        position: absolute;
        top: -20px;
        left: 0; }

/*
取扱業務
-------------------------------------*/
#PageBusiness.pageIndex .yellowTxt {
  font-weight: 700; }

/*
会社分割
-------------------------------------*/
#PageBunkatsu.pageIndex .contBox01 .contSubBox01 figure img {
  width: 100%;
  height: auto; }
#PageBunkatsu.pageIndex .contBox01 .contSubBox01 figure figcaption {
  color: #888888;
  font-size: 14px; }
#PageBunkatsu.pageIndex .contBox05 {
  position: relative; }
  #PageBunkatsu.pageIndex .contBox05::after {
    content: "";
    display: block;
    background-color: #f5f5f5;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1; }
  #PageBunkatsu.pageIndex .contBox05 .contSubBox01 {
    background-color: #fff;
    border: 1px solid #552b11; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox {
      background-color: #f5f5f5; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox h5 {
        color: #552b11;
        font-weight: 700;
        text-align: center; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox p {
        color: #552b11; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li {
        background-color: #3b2e26;
        color: #fff;
        text-align: center;
        font-family: "Noto Serif JP", serif;
        font-weight: 600;
        position: relative; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li::after {
          content: "";
          background: url(../img/contents/bunkatsu/arrow01.png) no-repeat center center;
          background-repeat: no-repeat;
          background-size: 32px auto;
          width: 32px;
          height: 15px;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%); }
  #PageBunkatsu.pageIndex .contBox05 .contSubBox02 {
    background-color: #cc9b30;
    color: #fff; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p {
      font-family: "Noto Serif JP", serif;
      font-weight: 600;
      position: relative; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p::after {
        content: "";
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 1px 50px;
        width: 1px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%); }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 p {
      text-align: center; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 {
      border-bottom: 1px solid #fff; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .tel {
        font-family: "Noto Serif JP", serif;
        font-weight: 700; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .tel a {
          color: #fff; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box02 .mail a {
      color: #fff;
      text-decoration: none; }

/*
日本企業再建研究会
-------------------------------------*/
#PageAdr.pageIndex .contBox01 .contSubBox01 dl {
  color: #552b11;
  border-top: 1px solid #552b11;
  border-bottom: 1px solid #552b11; }
  #PageAdr.pageIndex .contBox01 .contSubBox01 dl dt {
    font-family: "Noto Serif JP", serif;
    font-weight: 600; }
#PageAdr.pageIndex .contBox01 .contSubBox01 figure {
  width: 100%; }
  #PageAdr.pageIndex .contBox01 .contSubBox01 figure a {
    display: block; }
  #PageAdr.pageIndex .contBox01 .contSubBox01 figure img {
    width: 100%;
    height: auto; }
#PageAdr.pageIndex .contBox02 .contSubBox01 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #PageAdr.pageIndex .contBox02 .contSubBox01 ul li img {
    width: 100%;
    height: auto; }

/*
事務所案内
-------------------------------------*/
#PageOffice.pageIndex .contBox01 ul li a {
  color: #552b11;
  text-decoration: none;
  display: block;
  background: url(../img/contents/office/arrow01.png) no-repeat center bottom; }
#PageOffice.pageIndex .contBox02 .contSubBox03 {
  background-color: #f5f5f5; }
#PageOffice.pageIndex .contBox02 .contSubBox04 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
#PageOffice.pageIndex .contBox04 .contSubBox table {
  width: 100%; }
  #PageOffice.pageIndex .contBox04 .contSubBox table tr:nth-of-type(odd) {
    background-color: #f5f5f5; }
  #PageOffice.pageIndex .contBox04 .contSubBox table tr th {
    font-weight: 400; }
#PageOffice.pageIndex .contBox05 {
  position: relative; }
  #PageOffice.pageIndex .contBox05::after {
    content: "";
    display: block;
    background-color: #f5f5f5;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 h4 {
    color: #552b11;
    font-family: "Noto Serif JP", serif;
    font-weight: 600;
    text-align: center; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 p {
    text-align: center; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox {
    background-color: #cc9b30;
    color: #fff; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 {
      border-bottom: 1px solid #fff; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .tel {
        font-family: "Noto Serif JP", serif;
        font-weight: 700; }
        #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .tel a {
          color: #fff; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box02 .mail a {
      color: #fff;
      text-decoration: none; }
  #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox {
    background-color: #fff;
    border: 1px solid #3b2e26;
    position: relative; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%); }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox h4, #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox p {
      color: #552b11;
      text-align: center; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox h4 {
      font-weight: 700; }
  #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox {
    background-color: #f5f5f5; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox h5 {
      color: #552b11;
      font-weight: 700;
      text-align: center; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox p {
      color: #552b11; }
  #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li {
      background-color: #3b2e26;
      color: #fff;
      text-align: center;
      font-family: "Noto Serif JP", serif;
      font-weight: 600;
      position: relative; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li::after {
        content: "";
        background: url(../img/contents/bunkatsu/arrow01.png) no-repeat center center;
        background-repeat: no-repeat;
        background-size: 32px auto;
        width: 32px;
        height: 15px;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%); }

/*
著書・論文
-------------------------------------*/
#PageBooks.pageIndex .contBox01 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  #PageBooks.pageIndex .contBox01 ul li a {
    color: #552b11;
    text-decoration: none;
    display: block;
    background: url(../img/contents/office/arrow01.png) no-repeat center bottom; }

/*
後藤孝典が語る メインイメージ
-------------------------------------*/
#PageGototakanori #MainImg {
  background-color: #3b2e26;
  position: relative; }
  #PageGototakanori #MainImg #MainImgInner .imgBox {
    background: url(../img/contents/gototakanori/main_visu.jpg) no-repeat right center;
    background-size: cover; }
  #PageGototakanori #MainImg #MainImgInner h2 {
    background: url(../img/contents/top/title_logo.png) no-repeat left top;
    background-size: 32px auto; }
    #PageGototakanori #MainImg #MainImgInner h2::before {
      content: "";
      display: block;
      background-color: #d0d0d0;
      width: 50px;
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
  #PageGototakanori #MainImg #MainImgInner p {
    color: #fff; }

/*
後藤孝典が語る
-------------------------------------*/
#PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont {
  background-color: #f5f5f5;
  border: 1px solid #3b2e26; }
  #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .date {
    color: #cc9b30; }
  #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .title {
    color: #552b11;
    font-weight: bold; }

/*
後藤孝典が語る（記事詳細）
-------------------------------------*/
#PageGototakanori.pageEntry .contBox01 .contSubBox01 {
  background-color: #f5f5f5;
  border: 1px solid #3b2e26; }
  #PageGototakanori.pageEntry .contBox01 .contSubBox01 .date {
    color: #cc9b30; }

/*
お知らせブログ（カテゴリ）
-------------------------------------*/
#PageBlog.pageCategory .contBox01 .entryType01 dl:nth-of-type(odd) {
  background-color: #f5f5f5; }
#PageBlog.pageCategory .contBox01 .entryType01 dl a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-decoration: none;
  color: #222; }
#PageBlog.pageCategory .contBox01 .entryType01 dl .date {
  color: #cc9b30;
  text-align: center;
  width: 20%;
  line-height: 1.8; }
#PageBlog.pageCategory .contBox01 .entryType01 dl .title {
  width: 80%;
  line-height: 1.8; }
#PageBlog.pageCategory .contBox01 .entryType02 .post .postCont {
  background-color: #f5f5f5;
  border: 1px solid #3b2e26; }
  #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .date {
    color: #cc9b30; }
  #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .title {
    color: #552b11;
    font-weight: bold; }

/*
お知らせブログ（記事詳細）
-------------------------------------*/
#PageBlog.pageEntry .contBox01 .contSubBox01 {
  background-color: #f5f5f5;
  border: 1px solid #3b2e26; }
  #PageBlog.pageEntry .contBox01 .contSubBox01 .date {
    color: #cc9b30; }

/*
お問い合わせ（共通）
-------------------------------------*/
#PageContact #Main .error_messe {
  text-align: center;
  color: #cc9b30; }
#PageContact #Main .formBox .tableTypeForm {
  width: 100%; }
  #PageContact #Main .formBox .tableTypeForm tbody tr th {
    position: relative;
    font-weight: 400; }
    #PageContact #Main .formBox .tableTypeForm tbody tr th::before {
      content: "必須";
      position: absolute;
      left: 0;
      display: block;
      box-sizing: border-box;
      background-color: #cc9b30;
      line-height: 1;
      text-align: center;
      color: #fff; }
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="text"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="email"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="tel"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
    border: 1px solid #3b2e26;
    border-radius: 0 !important;
    background-color: #fff;
    -webkit-appearance: none !important; }
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="text"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="email"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="tel"],
  #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
    display: block;
    width: 100%; }
  #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
    resize: vertical; }
  #PageContact #Main .formBox .tableTypeForm tbody tr.noAttention th::before {
    content: "任意";
    border: 1px solid #cc9b30;
    background-color: #fff;
    color: #cc9b30; }
#PageContact #Main .listTypeButton {
  text-align: center; }
  #PageContact #Main .listTypeButton li.submit .btnTypeBasic, #PageContact #Main .listTypeButton li.back .btnTypeBasic {
    border-radius: 0 !important;
    padding-top: 0;
    letter-spacing: .1em;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px; }
    #PageContact #Main .listTypeButton li.submit .btnTypeBasic::after, #PageContact #Main .listTypeButton li.back .btnTypeBasic::after {
      display: none; }
  #PageContact #Main .listTypeButton li.back {
    margin-top: 50px; }
#PageContact #Main .txtTypeCenter + .error_messe {
  margin-top: 40px; }

/*
お問い合わせ（入力画面）
-------------------------------------*/
/*
お問い合わせ（確認画面）
-------------------------------------*/
#PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr:nth-of-type(4) th::before {
  content: "任意";
  border: 1px solid #cc9b30;
  background-color: #fff;
  color: #cc9b30; }
#PageContact.pageConfirm #Main .txtTypeCenter {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 0; }

/*
お問い合わせ（完了画面）
-------------------------------------*/
#PageContact.pageThanks #Main p {
  text-align: center; }

@media print, screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex #MainImg {
    height: 910px;
    padding-left: 50px;
    padding-bottom: 50px; }
    #Page.pageIndex #MainImg::after {
      bottom: -50px;
      height: 820px;
      width: calc(100% - 60px); }
    #Page.pageIndex #MainImg #MainImgInner {
      background: url(../img/contents/top/main_visu.jpg) no-repeat center center;
      background-size: cover; }
      #Page.pageIndex #MainImg #MainImgInner h2 {
        font-size: 60px;
        top: 295px;
        left: 100px; }
        #Page.pageIndex #MainImg #MainImgInner h2 b::after {
          height: 106px;
          width: 650px;
          top: -5px;
          left: -45px; }
      #Page.pageIndex #MainImg #MainImgInner p {
        font-size: 24px;
        top: 433px;
        left: 100px; }
        #Page.pageIndex #MainImg #MainImgInner p.disp_sp {
          display: none; }
        #Page.pageIndex #MainImg #MainImgInner p b::after {
          height: 70px;
          width: 784px;
          top: -18px;
          left: -40px; }
  #Page.pageIndex #ContBox01 {
    padding: 120px 0; }
    #Page.pageIndex #ContBox01::after {
      top: -50px;
      width: 60px;
      height: 50px; }
    #Page.pageIndex #ContBox01 .contSubBox01 .titleTypeBasic {
      padding: 26px 0;
      margin-bottom: 40px; }
    #Page.pageIndex #ContBox01 .contSubBox01 .entryBox {
      margin-bottom: 48px;
      padding: 0 50px; }
      #Page.pageIndex #ContBox01 .contSubBox01 .entryBox img {
        width: 167px !important;
        height: auto !important;
        margin-right: 50px !important;
        display: block !important; }
  #Page.pageIndex #ContBox02 {
    padding: 200px 0 120px; }
    #Page.pageIndex #ContBox02::after {
      height: calc(100% - 615px);
      top: 615px; }
    #Page.pageIndex #ContBox02 h3 {
      margin-bottom: 70px; }
    #Page.pageIndex #ContBox02 .contSubBox01 ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul li {
        width: calc(50% - 20px);
        margin-bottom: 60px; }
        #Page.pageIndex #ContBox02 .contSubBox01 ul li a:hover {
          opacity: 1.0; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a:hover figure::after {
            background-color: #574235; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a:hover figure > img {
            transform: scale(1.1); }
        #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure {
          overflow: hidden; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure::after {
            transition: all 0.3s;
            background-size: 12px auto;
            background-position: left 27px center; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure img {
            transition: all 0.3s;
            height: 260px; }
        #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl {
          padding: 30px 30px; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dt {
            font-size: 28px;
            margin-bottom: 10px; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dd {
            line-height: 2.0; }
  #Page.pageIndex #ContBox03 {
    padding: 150px 0 0; }
    #Page.pageIndex #ContBox03 h3 {
      margin-bottom: 30px; }
    #Page.pageIndex #ContBox03 .contSubBox01 {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch; }
      #Page.pageIndex #ContBox03 .contSubBox01 .bgImg {
        width: calc(100% - 520px); }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList {
        width: 520px; }
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list a {
          transition: all 0.3s; }
          #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list a:hover {
            opacity: 1.0; }
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a:hover,
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a:hover {
          background-color: #49382e; }
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a {
          font-size: 24px;
          padding: 70px 140px 70px 100px;
          background-size: 58px auto;
          background-position: center right 85px; }
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a {
          font-size: 20px;
          padding: 50px 60px 50px 30px;
          background-size: 26px auto;
          background-position: center right 30px; }
          #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a small {
            font-size: 12px;
            line-height: 2.0; }
        #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a {
          font-size: 20px;
          padding: 25px 60px 25px 40px;
          background-size: 26px auto;
          background-position: center right 55px; }
          #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a:hover {
            background-color: #ddae46; }
          #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a span {
            padding: 35px 0;
            display: block; }
  #Page.pageIndex #ContBox04 {
    padding: 160px 0 0; }
    #Page.pageIndex #ContBox04 .innerBasic {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    #Page.pageIndex #ContBox04 .contSubBox01 {
      width: 290px; }
      #Page.pageIndex #ContBox04 .contSubBox01 h3 {
        margin-top: 20px;
        margin-bottom: 55px; }
      #Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic {
        width: 210px;
        margin: 0;
        padding: 23px 30px 23px 30px; }
        #Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic span::after {
          right: -25px; }
    #Page.pageIndex #ContBox04 .contSubBox02 {
      width: calc(100% - 290px); }
      #Page.pageIndex #ContBox04 .contSubBox02 .post li {
        padding: 42px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        #Page.pageIndex #ContBox04 .contSubBox02 .post li a:hover .linkMore {
          text-decoration: none; }
        #Page.pageIndex #ContBox04 .contSubBox02 .post li .date {
          width: 110px; }
        #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont {
          width: calc(100% - 110px); }
          #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont h4 {
            font-size: 18px;
            margin-bottom: 6px; }
  #Page.pageIndex #ContBox05 {
    padding: 160px 0 200px; }
    #Page.pageIndex #ContBox05 .innerBasic {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    #Page.pageIndex #ContBox05 .contSubBox01 {
      width: 505px; }
      #Page.pageIndex #ContBox05 .contSubBox01 h3 {
        margin-top: 20px;
        margin-bottom: 53px; }
      #Page.pageIndex #ContBox05 .contSubBox01 img {
        width: 456px;
        height: 54px;
        margin-bottom: 10px; }
      #Page.pageIndex #ContBox05 .contSubBox01 .address {
        margin-top: 46px; }
        #Page.pageIndex #ContBox05 .contSubBox01 .address p {
          line-height: 1.8; }
          #Page.pageIndex #ContBox05 .contSubBox01 .address p:nth-of-type(1) {
            margin-bottom: 18px; }
    #Page.pageIndex #ContBox05 .contSubBox02 {
      width: calc(100% - 505px); }
      #Page.pageIndex #ContBox05 .contSubBox02 iframe {
        height: 440px; }

  #PageFeature #MainImg,
  #PageBusiness #MainImg,
  #PageBunkatsu #MainImg,
  #PageAdr #MainImg,
  #PageOffice #MainImg,
  #PageBooks #MainImg,
  #PageBlog #MainImg,
  #PageContact #MainImg,
  #PagePolicy #MainImg {
    height: 390px;
    padding-top: 110px; }

  /*
  当法律事務所の強み
  -------------------------------------*/
  #PageFeature.pageIndex .contBox01 {
    padding: 110px 0 115px; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 {
      width: 1000px;
      margin: 0 auto; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 108px; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .wrap.disp_sp {
        display: none; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 img {
        width: 323px;
        height: auto;
        margin-right: 50px; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox {
        width: calc(100% - 373px);
        margin-top: 20px; }
        #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox ul {
          padding: 35px 55px;
          margin-bottom: 60px; }
          #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox ul li {
            font-size: 20px;
            line-height: 1.9; }
            #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox ul li::after {
              top: 16px; }
        #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox .txtBox {
          position: relative; }
          #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox .txtBox::after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            right: 0;
            background: url(../img/contents/feature/img_02.jpg) no-repeat center center;
            background-size: 130px auto;
            width: 130px;
            height: 130px; }
          #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox .txtBox p:nth-of-type(1) {
            margin-bottom: 52px;
            position: relative; }
            #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox .txtBox p:nth-of-type(1)::after {
              content: "";
              display: block;
              position: absolute;
              bottom: -40px;
              left: 0;
              width: 469px;
              height: 21px;
              background: url(../img/contents/feature/arrow01.png) no-repeat center center;
              background-size: 469px auto; }
  #PageFeature.pageIndex .contBox02 {
    padding: 140px 0 150px; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 {
      width: 1000px;
      margin: 0 auto; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li {
        padding: 50px 100px 60px;
        margin-bottom: 50px; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li::after {
          width: 60px;
          height: 60px;
          line-height: 60px;
          font-size: 28px; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(1)::after {
          content: "1"; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(2)::after {
          content: "2"; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(3)::after {
          content: "3"; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) {
          padding-top: 77px; }
          #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4)::after {
            content: "4"; }
          #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p {
            margin-bottom: 20px; }
            #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p:last-of-type {
              margin-bottom: 0; }
            #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p .redTxt {
              text-decoration: underline; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dt {
          font-size: 28px;
          margin-bottom: 30px; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dd {
          line-height: 2.0; }

  /*
  取扱業務
  -------------------------------------*/
  #PageBusiness.pageIndex #Main .titleTypeBasic {
    margin: 20px 0 30px;
    padding: 15px 0; }
  #PageBusiness.pageIndex #Main .contBox01 {
    padding-top: 115px;
    padding-bottom: 115px; }

  /*
  会社分割
  -------------------------------------*/
  #PageBunkatsu.pageIndex .titleTypeBg {
    padding: 28px 40px;
    font-size: 24px;
    margin-bottom: 48px; }
  #PageBunkatsu.pageIndex .contBox01 {
    padding: 120px 0 80px; }
    #PageBunkatsu.pageIndex .contBox01 .contSubBox01 figure figcaption {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 48px; }
    #PageBunkatsu.pageIndex .contBox01 .contSubBox01 .txtBox {
      width: 800px;
      margin: 0 auto; }
      #PageBunkatsu.pageIndex .contBox01 .contSubBox01 .txtBox p {
        margin-bottom: 30px; }
  #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox,
  #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 40px;
    padding-left: 40px;
    margin-bottom: 85px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox .wrap,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox .wrap {
      width: calc(50% - 20px); }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox .titleTypeBasic,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox .titleTypeBasic {
      padding: 15px 0;
      margin-bottom: 20px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox p,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox p {
      margin-bottom: 25px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox ul li,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox ul li {
      margin-bottom: 6px; }
  #PageBunkatsu.pageIndex .contBox04 .contSubBox01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;
    margin-bottom: 165px; }
    #PageBunkatsu.pageIndex .contBox04 .contSubBox01 img {
      width: 350px; }
    #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox {
      width: calc(100% - 400px); }
      #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox p {
        margin-bottom: 30px; }
        #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox p:last-of-type {
          margin-bottom: 0; }
  #PageBunkatsu.pageIndex .contBox05 {
    padding-bottom: 200px;
    padding: 0 0 120px; }
    #PageBunkatsu.pageIndex .contBox05::after {
      height: calc(100% - 395px);
      top: 395px; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox01 {
      padding: 100px 100px;
      margin-bottom: 95px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox {
        padding: 40px 30px 45px;
        margin-bottom: 105px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox h5 {
          font-size: 20px;
          margin-bottom: 15px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox p {
          text-align: center; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li {
        width: calc(calc(100% - 80px) / 3);
        padding: 55px 30px;
        font-size: 20px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li::after {
          background-size: 43px auto;
          width: 43px;
          height: 21px;
          top: -60px; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 {
      padding: 70px 20px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p {
        font-size: 20px;
        margin-bottom: 70px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p::after {
          height: 50px;
          bottom: -70px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 p {
        text-align: center; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 {
        width: 320px;
        margin: 0 auto;
        padding-bottom: 22px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .tel {
          font-size: 46px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .fax {
          margin-top: -22px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box02 .mail {
        margin-top: 10px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box02 .mail a:hover {
          text-decoration: underline; }

  /*
  日本企業再建研究会
  -------------------------------------*/
  #PageAdr.pageIndex .contBox01 {
    padding: 115px 0 80px; }
    #PageAdr.pageIndex .contBox01 .innerBasic {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    #PageAdr.pageIndex .contBox01 .contSubBox01 {
      width: 460px; }
      #PageAdr.pageIndex .contBox01 .contSubBox01 dl {
        padding: 50px 30px 55px;
        margin-bottom: 50px;
        margin-top: 10px; }
        #PageAdr.pageIndex .contBox01 .contSubBox01 dl dt {
          font-size: 28px;
          letter-spacing: 0em;
          margin-bottom: 28px; }
        #PageAdr.pageIndex .contBox01 .contSubBox01 dl dd {
          font-size: 12px;
          line-height: 2.2; }
      #PageAdr.pageIndex .contBox01 .contSubBox01 figure a {
        margin-bottom: 30px; }
        #PageAdr.pageIndex .contBox01 .contSubBox01 figure a:last-of-type {
          margin-bottom: 0; }
        #PageAdr.pageIndex .contBox01 .contSubBox01 figure a:hover {
          opacity: 0.7; }
    #PageAdr.pageIndex .contBox01 .contSubBox02 {
      width: calc(100% - 540px); }
      #PageAdr.pageIndex .contBox01 .contSubBox02 p {
        margin-bottom: 30px; }
  #PageAdr.pageIndex .contBox02 {
    padding-bottom: 195px; }
    #PageAdr.pageIndex .contBox02 .contSubBox01 ul li {
      width: calc(calc(100% - 40px) / 6);
      margin-bottom: 8px; }
      #PageAdr.pageIndex .contBox02 .contSubBox01 ul li img {
        width: 100%;
        height: auto; }

  /*
  事務所案内
  -------------------------------------*/
  #PageOffice.pageIndex .titleTypeBg {
    padding: 27px 40px;
    font-size: 24px;
    margin-bottom: 65px; }
  #PageOffice.pageIndex .titleTypeBasic {
    padding: 15px 0;
    margin-bottom: 20px; }
    #PageOffice.pageIndex .titleTypeBasic + p {
      margin-bottom: 20px; }
  #PageOffice.pageIndex .titleTypeBorder {
    font-size: 20px;
    padding: 17px 47px;
    margin-bottom: 40px; }
    #PageOffice.pageIndex .titleTypeBorder span {
      font-size: 16px;
      position: relative;
      top: -2px; }
  #PageOffice.pageIndex .contBox01 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 112px 0 124px; }
    #PageOffice.pageIndex .contBox01 ul li {
      margin-right: 92px; }
      #PageOffice.pageIndex .contBox01 ul li:last-of-type {
        margin-right: 0; }
      #PageOffice.pageIndex .contBox01 ul li a {
        text-align: center;
        padding-bottom: 16px;
        background-size: 12px auto; }
  #PageOffice.pageIndex .contBox02 .contSubBox01 {
    padding: 0 40px 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #PageOffice.pageIndex .contBox02 .contSubBox01 img {
      width: 350px;
      height: auto; }
    #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox {
      width: calc(100% - 400px);
      padding-top: 10px; }
      #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox p {
        margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox .align-right {
        font-size: 16px; }
        #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox .align-right span {
          font-size: 14px; }
  #PageOffice.pageIndex .contBox02 .contSubBox03 {
    background-color: #f5f5f5; }
  #PageOffice.pageIndex .contBox02 .contSubBox04 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  #PageOffice.pageIndex .contBox03 .titleTypeBg {
    margin-bottom: 50px; }
  #PageOffice.pageIndex .contBox03 .contSubBox01,
  #PageOffice.pageIndex .contBox03 .contSubBox02 {
    padding: 0 40px; }
    #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul li,
    #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul li {
      margin-bottom: 6px; }
      #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul li:last-of-type,
      #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul li:last-of-type {
        margin-bottom: 0; }
  #PageOffice.pageIndex .contBox03 .contSubBox01 {
    margin-bottom: 55px; }
    #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox .wrap {
        width: calc(calc(100% - 40px) / 2); }
  #PageOffice.pageIndex .contBox03 .contSubBox02 {
    margin-bottom: 30px; }
  #PageOffice.pageIndex .contBox03 .contSubBox03 {
    background-color: #f5f5f5;
    width: calc(100% - 80px);
    margin: 0 auto;
    padding: 70px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #PageOffice.pageIndex .contBox03 .contSubBox03 ul {
      width: calc(calc(100% - 40px) / 2); }
      #PageOffice.pageIndex .contBox03 .contSubBox03 ul li {
        margin-bottom: 22px; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li:last-of-type {
          margin-bottom: 0; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li dl dt {
          font-size: 18px;
          line-height: 1.8; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li dl dd {
          padding-left: 25px;
          line-height: 2.0; }
  #PageOffice.pageIndex .contBox03 .contSubBox04 {
    padding: 50px 40px 100px; }
    #PageOffice.pageIndex .contBox03 .contSubBox04 ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageOffice.pageIndex .contBox03 .contSubBox04 ul li {
        width: calc(calc(100% - 60px) / 8); }
        #PageOffice.pageIndex .contBox03 .contSubBox04 ul li img {
          width: 133px;
          height: auto; }
  #PageOffice.pageIndex .contBox04 .titleTypeBg {
    margin-bottom: 50px; }
  #PageOffice.pageIndex .contBox04 .contSubBox {
    padding: 0 40px; }
    #PageOffice.pageIndex .contBox04 .contSubBox .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 50px; }
    #PageOffice.pageIndex .contBox04 .contSubBox img {
      width: 350px;
      height: 420px; }
    #PageOffice.pageIndex .contBox04 .contSubBox .tableWrap {
      width: calc(100% - 400px); }
    #PageOffice.pageIndex .contBox04 .contSubBox table tr th {
      width: 23%;
      padding: 15px 30px; }
    #PageOffice.pageIndex .contBox04 .contSubBox table tr td {
      width: 77%;
      padding: 15px 30px 15px 0; }
  #PageOffice.pageIndex .contBox04 .contSubBox02 {
    margin-bottom: 100px; }
  #PageOffice.pageIndex .contBox05::after {
    height: calc(100% - 340px);
    top: 340px; }
  #PageOffice.pageIndex .contBox05 .titleTypeBg {
    margin-bottom: 50px; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 h4 {
    font-size: 24px; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 > p {
    font-size: 14px;
    margin-bottom: 22px; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox {
    padding: 20px 0 45px;
    width: 610px;
    margin: 0 auto; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 {
      width: 320px;
      margin: 0 auto;
      padding-bottom: 22px; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .tel {
        font-size: 46px; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .fax {
        margin-top: -22px; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box02 .mail {
      margin-top: 10px; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box02 .mail a:hover {
        text-decoration: underline; }
  #PageOffice.pageIndex .contBox05 .contSubBox02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 40px 80px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box {
      width: calc(50% - 20px); }
      #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox {
        padding: 44px 50px; }
        #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox h4 {
          font-size: 20px;
          margin-bottom: 8px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box01 .txtBox::after {
      background: url(../img/contents/office/arrow02.png) no-repeat center center;
      background-size: 43px auto;
      width: 43px;
      height: 223px;
      bottom: -253px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box02 .txtBox::after {
      background: url(../img/contents/office/arrow03.png) no-repeat center center;
      background-size: 43px auto;
      width: 43px;
      height: 21px;
      bottom: -50px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box02 .txtBox:nth-of-type(1) {
      margin-bottom: 78px; }
  #PageOffice.pageIndex .contBox05 .contSubBox03 {
    padding: 90px 40px 80px;
    background-color: #fff;
    border: 1px solid #552b11;
    width: calc(100% - 80px);
    margin: 0 auto; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 + p {
      text-align: center;
      font-size: 14px;
      margin-top: 10px; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead {
      padding: 0 50px 50px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead p {
        text-align: center;
        margin-bottom: 20px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px; }
        #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul li {
          background-color: #cc9b30;
          color: #fff;
          text-align: center;
          padding: 8px 0;
          margin-right: 25px;
          width: 160px; }
          #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul li:last-of-type {
            margin-right: 0; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody {
      padding: 0 20px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox {
        padding: 40px 30px 45px;
        margin-bottom: 105px; }
        #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox h5 {
          font-size: 20px;
          margin-bottom: 15px; }
        #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox p {
          text-align: center; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li {
        width: calc(calc(100% - 80px) / 3);
        padding: 55px 30px;
        font-size: 20px; }
        #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li::after {
          background-size: 43px auto;
          width: 43px;
          height: 21px;
          top: -60px; }
  #PageOffice.pageIndex .contBox05 .contSubBox04 {
    padding: 95px 40px 80px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 .titleTypeBgYe {
      font-size: 20px;
      padding: 15px 30px;
      margin-bottom: 20px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li p {
      margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li p:last-of-type {
        margin-bottom: 45px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul {
      margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul > li {
        margin-bottom: 8px; }
        #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul > li:last-of-type {
          margin-bottom: 0; }
  #PageOffice.pageIndex .contBox06 {
    padding: 95px 0 170px; }
    #PageOffice.pageIndex .contBox06 .titleTypeBg {
      margin-bottom: 40px; }
    #PageOffice.pageIndex .contBox06 .contSubBox01 {
      padding: 0 40px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 p {
        margin-bottom: 35px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 iframe {
        width: 100%;
        height: 440px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 .linkTypeBlank {
        width: 150px;
        margin-bottom: 8px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 ul {
        margin-bottom: 25px; }
        #PageOffice.pageIndex .contBox06 .contSubBox01 ul li {
          margin-bottom: 8px; }
        #PageOffice.pageIndex .contBox06 .contSubBox01 ul + p {
          font-size: 14px; }

  /*
  著書・論文
  -------------------------------------*/
  #PageBooks.pageIndex .titleTypeBg {
    font-size: 24px;
    padding: 26px 40px; }
  #PageBooks.pageIndex .titleTypeBasic {
    font-size: 20px;
    padding: 15px 0;
    margin-bottom: 20px; }
  #PageBooks.pageIndex .contBox01 {
    padding: 110px 0 120px; }
    #PageBooks.pageIndex .contBox01 ul li {
      margin-right: 100px; }
      #PageBooks.pageIndex .contBox01 ul li:last-of-type {
        margin-right: 0; }
      #PageBooks.pageIndex .contBox01 ul li a {
        padding-bottom: 15px;
        background-size: 12px auto; }
  #PageBooks.pageIndex .contBox02 .contSubBox01 {
    padding: 30px 40px 140px; }
    #PageBooks.pageIndex .contBox02 .contSubBox01 ul li {
      border-bottom: 1px solid #d0d0d0;
      padding: 50px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li figure {
        width: 167px;
        margin-top: 10px; }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li figure img {
          width: 100%;
          height: auto; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox {
        width: calc(100% - 217px); }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .bookTitle {
          margin-bottom: 14px; }
          #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .bookTitle span {
            font-size: 20px;
            font-weight: 700;
            color: #552b11; }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox p {
          line-height: 2.0;
          margin-bottom: 20px; }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .align-right {
          font-size: 14px; }
  #PageBooks.pageIndex .contBox03 {
    padding-bottom: 180px; }
    #PageBooks.pageIndex .contBox03 .contSubBox01 {
      padding: 40px 40px 46px; }
      #PageBooks.pageIndex .contBox03 .contSubBox01 ul li {
        margin-bottom: 6px; }
    #PageBooks.pageIndex .contBox03 .contSubBox02 {
      padding: 0 40px; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul.listBar {
        margin-bottom: 22px; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul .dotTypeBar {
        margin-left: 23px;
        margin-bottom: 6px; }

  /*
  後藤孝典が語る ヘッダー・メインイメージ
  -------------------------------------*/
  #PageGototakanori #Header #ContBoxHeader #HeaderMenu .subNav,
  #PageGototakanori #Header #ContBoxHeader #HeaderMenu .mainNav {
    display: none; }
  #PageGototakanori #MainImg {
    height: 550px; }
    #PageGototakanori #MainImg #MainImgInner {
      position: relative;
      height: 100%; }
      #PageGototakanori #MainImg #MainImgInner .imgBox {
        height: 100%;
        width: 54.2%;
        position: absolute;
        top: 0;
        right: 0; }
      #PageGototakanori #MainImg #MainImgInner h2 {
        position: absolute;
        top: 50%;
        transform: translate(0, -85px);
        margin: 0 auto;
        padding-top: 40px; }
        #PageGototakanori #MainImg #MainImgInner h2.titleTypeMainVisu[data-subtitle]::after {
          top: 1px;
          padding-left: 49px; }
        #PageGototakanori #MainImg #MainImgInner h2::before {
          left: 130px;
          top: 17px; }
      #PageGototakanori #MainImg #MainImgInner p {
        position: absolute;
        top: 50%;
        transform: translate(0, 35px);
        font-size: 14px; }

  /*
  後藤孝典が語る
  -------------------------------------*/
  #PageGototakanori.pageIndex .contBox01 {
    padding: 140px 0 255px; }
    #PageGototakanori.pageIndex .contBox01 .contSubBox01 {
      width: 1000px;
      margin: 0 auto; }
      #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post {
        margin-bottom: 95px; }
        #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont {
          padding: 90px 100px 80px;
          margin-bottom: 50px; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont:last-of-type {
            margin-bottom: 0; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .date {
            margin-bottom: 4px; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .title {
            font-size: 20px;
            margin-bottom: 35px; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .entryBody {
            margin-bottom: 40px; }

  /*
  後藤孝典が語る（記事詳細）
  -------------------------------------*/
  #PageGototakanori.pageEntry .contBox01 {
    padding: 140px 0 220px; }
    #PageGototakanori.pageEntry .contBox01 .contSubBox01 {
      width: 1000px;
      margin: 0 auto 67px;
      padding: 90px 100px 110px; }
      #PageGototakanori.pageEntry .contBox01 .contSubBox01 .date {
        margin-bottom: 14px; }
      #PageGototakanori.pageEntry .contBox01 .contSubBox01 .titleTypeBasic {
        padding: 20px 0;
        margin-bottom: 50px; }

  /*
  お知らせブログ（カテゴリ）
  -------------------------------------*/
  #PageBlog.pageCategory .contBox01 {
    padding-bottom: 260px; }
    #PageBlog.pageCategory .contBox01 .entryType01 {
      padding: 115px 40px 75px; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl a {
        padding: 36px 0; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl .date {
        width: 180px; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl .title {
        width: calc(100% - 180px);
        padding: 0 23px; }
    #PageBlog.pageCategory .contBox01 .entryType02 {
      width: 1000px;
      margin: 0 auto;
      padding: 115px 40px 75px; }
      #PageBlog.pageCategory .contBox01 .entryType02 .post {
        margin-bottom: 95px; }
        #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont {
          padding: 90px 100px 80px;
          margin-bottom: 50px; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont:last-of-type {
            margin-bottom: 0; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .date {
            margin-bottom: 4px; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .title {
            font-size: 20px;
            margin-bottom: 35px; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .entryBody {
            margin-bottom: 40px; }

  /*
  お知らせブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry .contBox01 {
    padding: 115px 0 225px; }
    #PageBlog.pageEntry .contBox01 .contSubBox01 {
      width: 1000px;
      margin: 0 auto 65px;
      padding: 90px 100px 120px; }
      #PageBlog.pageEntry .contBox01 .contSubBox01 .date {
        margin-bottom: 12px; }
      #PageBlog.pageEntry .contBox01 .contSubBox01 .titleTypeBasic {
        padding: 20px 0;
        margin-bottom: 20px; }

  /*
  お問い合わせ（共通）
  -------------------------------------*/
  #PageContact #Main .innerBasic {
    padding: 114px 0 200px;
    width: 1000px; }
  #PageContact #Main .formBox .tableTypeForm {
    margin-bottom: 51px; }
    #PageContact #Main .formBox .tableTypeForm tbody tr th {
      vertical-align: top;
      padding-top: 16px;
      padding-left: 65px;
      width: 319px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr th::before {
        top: 18px;
        padding: 5px;
        width: 50px;
        height: 24px;
        font-size: 14px; }
    #PageContact #Main .formBox .tableTypeForm tbody tr td {
      padding-bottom: 50px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="text"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="email"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="tel"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
        padding: 17px 17px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
        height: 300px; }
    #PageContact #Main .formBox .tableTypeForm tbody tr.noAttention th::before {
      padding: 4px; }
    #PageContact #Main .formBox .tableTypeForm tbody tr.name td input[type="text"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name td input[type="email"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name td input[type="radio"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name td input[type="checkbox"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name td input[type="tel"], #PageContact #Main .formBox .tableTypeForm tbody tr.name_kana td input[type="text"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name_kana td input[type="email"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name_kana td input[type="radio"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name_kana td input[type="checkbox"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.name_kana td input[type="tel"], #PageContact #Main .formBox .tableTypeForm tbody tr.tel td input[type="text"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.tel td input[type="email"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.tel td input[type="radio"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.tel td input[type="checkbox"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.tel td input[type="tel"], #PageContact #Main .formBox .tableTypeForm tbody tr.mailaddress td input[type="text"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.mailaddress td input[type="email"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.mailaddress td input[type="radio"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.mailaddress td input[type="checkbox"],
    #PageContact #Main .formBox .tableTypeForm tbody tr.mailaddress td input[type="tel"] {
      width: 500px; }
  #PageContact #Main .listTypeButton {
    position: relative; }
    #PageContact #Main .listTypeButton li {
      display: inline-block; }
      #PageContact #Main .listTypeButton li.submit .btnTypeBasic, #PageContact #Main .listTypeButton li.back .btnTypeBasic {
        padding-bottom: 0;
        width: 318px;
        height: 79px;
        font-size: 16px;
        transition: all .3s; }
        #PageContact #Main .listTypeButton li.submit .btnTypeBasic:hover, #PageContact #Main .listTypeButton li.back .btnTypeBasic:hover {
          background-color: #574235;
          transition: all .3s; }
      #PageContact #Main .listTypeButton li.back .btnTypeBasic {
        padding-top: 27px; }
  #PageContact #Main .txtTypeCenter input:hover {
    text-decoration: underline; }

  /*
  お問い合わせ（入力画面）
  -------------------------------------*/
  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm #Main .formBox .tableTypeForm {
    margin-bottom: 51px; }
    #PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr:nth-of-type(4) th::before {
      padding: 4px; }
    #PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr th {
      padding-bottom: 16px; }
    #PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px; }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex #Main .innerBasic {
    width: 1000px;
    margin: 0 auto; }
  #PagePolicy.pageIndex #Main .contBox01 {
    padding-top: 105px;
    padding-bottom: 190px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox01 {
      margin-bottom: 95px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 h3 {
      font-size: 20px;
      padding: 14px 0;
      margin-top: 70px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 p {
      margin-top: 32px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 ul {
      margin-top: 32px; }
      #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 ul li {
        margin-bottom: 8px; } }
@media print, screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex #MainImg {
    height: 500px;
    padding-left: 15px;
    padding-bottom: 15px; }
    #Page.pageIndex #MainImg::after {
      bottom: 0;
      height: 420px;
      width: calc(100% - 15px); }
    #Page.pageIndex #MainImg #MainImgInner {
      background: url(../img/contents/top/main_visu_sp.jpg) no-repeat center center;
      background-size: cover; }
      #Page.pageIndex #MainImg #MainImgInner .inner {
        height: 100%; }
      #Page.pageIndex #MainImg #MainImgInner h2 {
        font-size: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 30px)); }
        #Page.pageIndex #MainImg #MainImgInner h2 b::after {
          height: 55px;
          width: 310px;
          top: -3px;
          left: -22px; }
        #Page.pageIndex #MainImg #MainImgInner h2 b em {
          white-space: nowrap; }
      #Page.pageIndex #MainImg #MainImgInner p {
        font-size: 16px;
        white-space: nowrap; }
        #Page.pageIndex #MainImg #MainImgInner p.disp_pc {
          display: none; }
        #Page.pageIndex #MainImg #MainImgInner p:nth-of-type(2) {
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% - 15px), 18px); }
          #Page.pageIndex #MainImg #MainImgInner p:nth-of-type(2) b::after {
            height: 41px;
            width: 262px;
            top: -10px;
            left: -17px; }
        #Page.pageIndex #MainImg #MainImgInner p:nth-of-type(3) {
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% + 10px), 53px); }
          #Page.pageIndex #MainImg #MainImgInner p:nth-of-type(3) b::after {
            height: 41px;
            width: 267px;
            top: -10px;
            left: -12px; }
  #Page.pageIndex #ContBox01 {
    padding: 60px 0; }
    #Page.pageIndex #ContBox01::after {
      top: -15px;
      width: 15px;
      height: 15px; }
    #Page.pageIndex #ContBox01 .contSubBox01 {
      overflow: hidden;
      padding: 0 15px; }
      #Page.pageIndex #ContBox01 .contSubBox01 .titleTypeBasic {
        font-size: 18px;
        padding: 15px 0;
        margin-bottom: 30px; }
      #Page.pageIndex #ContBox01 .contSubBox01 .entryBox {
        margin-bottom: 53px; }
        #Page.pageIndex #ContBox01 .contSubBox01 .entryBox img {
          width: 167px !important;
          height: auto !important;
          margin: 0 auto 20px !important;
          display: block !important;
          float: none !important; }
        #Page.pageIndex #ContBox01 .contSubBox01 .entryBox a {
          word-break: break-all; }
  #Page.pageIndex #ContBox02 {
    padding: 38px 0 40px; }
    #Page.pageIndex #ContBox02::after {
      height: calc(100% - 280px);
      top: 280px; }
    #Page.pageIndex #ContBox02 h3 {
      margin-bottom: 35px; }
    #Page.pageIndex #ContBox02 .contSubBox01 {
      padding: 0 15px; }
      #Page.pageIndex #ContBox02 .contSubBox01 ul {
        margin-bottom: 50px; }
        #Page.pageIndex #ContBox02 .contSubBox01 ul li {
          margin-bottom: 30px; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure::after {
            width: 45px;
            height: 45px;
            background-size: 9px auto; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a figure img {
            height: auto; }
          #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl {
            padding: 25px 20px; }
            #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dt {
              font-size: 20px;
              margin-bottom: 6px; }
            #Page.pageIndex #ContBox02 .contSubBox01 ul li a dl dd {
              line-height: 1.8; }
  #Page.pageIndex #ContBox03 {
    padding: 50px 0 0; }
    #Page.pageIndex #ContBox03 h3 {
      font-size: 26px;
      margin-bottom: 25px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .bgImg {
      height: 195px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a {
      font-size: 20px;
      padding: 40px 90px 40px 60px;
      background-size: 32px auto;
      background-position: center right 58px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a {
      font-size: 16px;
      padding: 20px 20px 20px 15px;
      background-size: 26px auto; }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a:nth-of-type(1) {
        background-position: bottom 18px right 40px; }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a:nth-of-type(2) {
        background-position: bottom 18px right 10px; }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a small {
        font-size: 12px;
        line-height: 2.0; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a {
      font-size: 18px;
      padding: 15px 45px 15px 15px;
      background-size: 26px auto;
      background-position: center right 15px; }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a figure {
        width: 48px;
        margin-right: 30px; }
      #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a span {
        padding: 20px 0;
        display: block;
        width: calc(100% - 78px); }
  #Page.pageIndex #ContBox04 {
    padding: 63px 15px 0; }
    #Page.pageIndex #ContBox04 .contSubBox01 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #Page.pageIndex #ContBox04 .contSubBox01 h3 {
        margin-top: 10px;
        margin-bottom: 25px; }
        #Page.pageIndex #ContBox04 .contSubBox01 h3.titleTypeMain[data-subtitle]::after {
          top: -20px; }
      #Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic {
        font-size: 14px;
        width: 130px;
        height: 45px;
        margin: 0;
        padding: 10px 15px 10px 15px; }
        #Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic span {
          padding-right: 25px; }
          #Page.pageIndex #ContBox04 .contSubBox01 .btnTypeBasic span::after {
            background-size: 20px;
            right: -5px; }
    #Page.pageIndex #ContBox04 .contSubBox02 .post li {
      padding: 20px 0; }
      #Page.pageIndex #ContBox04 .contSubBox02 .post li .date {
        margin-bottom: 3px; }
      #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont h4 {
        font-size: 18px;
        margin-bottom: 0; }
      #Page.pageIndex #ContBox04 .contSubBox02 .post li .postCont p {
        line-height: 1.8;
        word-break: break-all; }
  #Page.pageIndex #ContBox05 {
    padding: 55px 15px 85px; }
    #Page.pageIndex #ContBox05 .contSubBox01 {
      margin-bottom: 20px; }
      #Page.pageIndex #ContBox05 .contSubBox01 h3 {
        margin-top: 20px;
        margin-bottom: 23px; }
      #Page.pageIndex #ContBox05 .contSubBox01 img {
        width: 100%;
        height: auto;
        margin-bottom: 4px; }
      #Page.pageIndex #ContBox05 .contSubBox01 > p {
        line-height: 1.8; }
      #Page.pageIndex #ContBox05 .contSubBox01 .address {
        margin-top: 16px; }
        #Page.pageIndex #ContBox05 .contSubBox01 .address p {
          line-height: 1.5; }
          #Page.pageIndex #ContBox05 .contSubBox01 .address p:nth-of-type(1) {
            margin-bottom: 18px; }
          #Page.pageIndex #ContBox05 .contSubBox01 .address p:nth-of-type(2) {
            line-height: 1.8; }
    #Page.pageIndex #ContBox05 .contSubBox02 iframe {
      height: 220px; }

  #PageFeature #MainImg,
  #PageBusiness #MainImg,
  #PageBunkatsu #MainImg,
  #PageAdr #MainImg,
  #PageOffice #MainImg,
  #PageBooks #MainImg,
  #PageBlog #MainImg,
  #PageContact #MainImg,
  #PagePolicy #MainImg {
    height: 240px;
    padding-top: 60px; }
    #PageFeature #MainImg h2,
    #PageBusiness #MainImg h2,
    #PageBunkatsu #MainImg h2,
    #PageAdr #MainImg h2,
    #PageOffice #MainImg h2,
    #PageBooks #MainImg h2,
    #PageBlog #MainImg h2,
    #PageContact #MainImg h2,
    #PagePolicy #MainImg h2 {
      left: 15px; }

  /*
  お知らせブログメインイメージ調整
  -------------------------------------*/
  #PageBlog #MainImg h2 {
    transform: translate(0, calc(50%  - 25px));
    line-height: 1.2; }

  /*
  当法律事務所の強み
  -------------------------------------*/
  #PageFeature.pageIndex .contBox01 {
    padding: 52px 15px 50px; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 55px; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 .wrap.disp_pc {
      display: none; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 ul {
      border: 1px solid #552b11;
      padding: 26px 15px;
      margin-bottom: 25px;
      position: relative; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 ul::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);
        width: 22px;
        height: 34px;
        background: url(../img/contents/feature/arrow01_sp.png) no-repeat center center;
        background-size: 22px auto; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 ul li {
        font-weight: 700;
        color: #552b11;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 7px; }
        #PageFeature.pageIndex .contBox01 .contSubBox01 ul li::after {
          top: 9px; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox {
      margin-top: 20px;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox img {
        width: 90px;
        height: auto;
        margin-right: 15px;
        display: block; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .infoBox p {
        width: calc(100% - 105px); }
    #PageFeature.pageIndex .contBox01 .contSubBox01 .txtBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .txtBox p {
        width: calc(100% - 95px);
        margin-right: 30px; }
      #PageFeature.pageIndex .contBox01 .contSubBox01 .txtBox img {
        width: 65px;
        height: auto;
        display: block; }
    #PageFeature.pageIndex .contBox01 .contSubBox01 > p {
      line-height: 1.8; }
  #PageFeature.pageIndex .contBox02 {
    padding: 60px 15px 40px; }
    #PageFeature.pageIndex .contBox02 .contSubBox01 ul li {
      padding: 40px 20px 35px;
      margin-bottom: 40px;
      text-align: justify; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li::after {
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 21px;
        top: -20px;
        left: 20px; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(1)::after {
        content: "1"; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(2)::after {
        content: "2"; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(3)::after {
        content: "3"; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) {
        padding-top: 75px; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4)::after {
          content: "4"; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dt[data-subtitle]::after {
          top: -30px; }
        #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p {
          margin-bottom: 20px; }
          #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p:last-of-type {
            margin-bottom: 0; }
          #PageFeature.pageIndex .contBox02 .contSubBox01 ul li:nth-of-type(4) dl dd p .redTxt {
            text-decoration: underline; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dt {
        font-size: 20px;
        margin-bottom: 18px; }
      #PageFeature.pageIndex .contBox02 .contSubBox01 ul li dl dd {
        line-height: 1.8;
        text-align: justify; }

  /*
  取扱業務
  -------------------------------------*/
  #PageBusiness.pageIndex #Main .titleTypeBasic {
    margin: 10px 0 25px; }
  #PageBusiness.pageIndex #Main .contBox01 {
    padding: 50px 15px 85px; }

  /*
  会社分割
  -------------------------------------*/
  #PageBunkatsu.pageIndex .titleTypeBg {
    padding: 21px 15px;
    font-size: 18px;
    margin-bottom: 42px; }
  #PageBunkatsu.pageIndex .contBox01 {
    padding: 50px 15px 25px; }
    #PageBunkatsu.pageIndex .contBox01 .contSubBox01 figure figcaption {
      text-align: left;
      margin-top: 12px;
      margin-bottom: 28px; }
    #PageBunkatsu.pageIndex .contBox01 .contSubBox01 .txtBox p {
      margin-bottom: 26px;
      line-height: 1.8; }
  #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox,
  #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox {
    margin-bottom: 50px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox .wrap,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox .wrap {
      padding: 0 15px;
      margin-bottom: 32px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox .titleTypeBasic,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox .titleTypeBasic {
      padding: 9px 0;
      margin-bottom: 20px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox p,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox p {
      margin-bottom: 12px; }
    #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox ul li,
    #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox ul li {
      margin-bottom: 2px; }
      #PageBunkatsu.pageIndex .contBox02 .contSubBox01 .txtBox ul li.dotTypeBasic::after,
      #PageBunkatsu.pageIndex .contBox03 .contSubBox01 .txtBox ul li.dotTypeBasic::after {
        top: 12px; }
  #PageBunkatsu.pageIndex .contBox04 .titleTypeBg {
    margin-bottom: 30px; }
  #PageBunkatsu.pageIndex .contBox04 .contSubBox01 {
    margin-bottom: 70px; }
    #PageBunkatsu.pageIndex .contBox04 .contSubBox01 img {
      width: 175px;
      height: auto;
      display: block;
      margin: 0 auto 20px; }
    #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox {
      padding: 0 15px; }
      #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox p {
        line-height: 1.8;
        margin-bottom: 25px; }
        #PageBunkatsu.pageIndex .contBox04 .contSubBox01 .txtBox p:last-of-type {
          margin-bottom: 0; }
  #PageBunkatsu.pageIndex .contBox05 {
    padding: 0 15px 100px; }
    #PageBunkatsu.pageIndex .contBox05::after {
      height: calc(100% - 115px);
      top: 115px; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox01 {
      padding: 35px 20px 40px;
      margin-bottom: 40px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox {
        padding: 27px 15px 24px;
        margin-bottom: 32px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox h5 {
          font-size: 18px;
          margin-bottom: 6px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtBox p {
          line-height: 1.8;
          text-align: justify; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li {
        width: calc(calc(100% - 20px) / 3);
        padding: 23px 0;
        font-size: 16px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li:nth-of-type(1) {
          padding-top: 35px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox01 .txtList li::after {
          background-size: 32px auto;
          width: 32px;
          height: 15px;
          top: -22px; }
    #PageBunkatsu.pageIndex .contBox05 .contSubBox02 {
      padding: 30px 20px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p {
        font-size: 18px;
        margin-bottom: 45px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 > p::after {
          height: 30px;
          bottom: -40px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 p {
        line-height: 1.8;
        text-align: center; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 {
        width: 100%;
        padding-bottom: 22px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .tel {
          font-size: 32px; }
        #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box01 .fax {
          margin-top: -10px; }
      #PageBunkatsu.pageIndex .contBox05 .contSubBox02 .box02 .mail {
        margin-top: 10px; }

  /*
  日本企業再建研究会
  -------------------------------------*/
  #PageAdr.pageIndex .contBox01 {
    padding: 50px 15px 50px; }
    #PageAdr.pageIndex .contBox01 .contSubBox01 dl {
      padding: 34px 10px 35px;
      margin-bottom: 40px; }
      #PageAdr.pageIndex .contBox01 .contSubBox01 dl dt {
        font-size: 20px;
        letter-spacing: 0em;
        margin-bottom: 21px; }
      #PageAdr.pageIndex .contBox01 .contSubBox01 dl dd {
        font-size: 12px;
        line-height: 2.0; }
    #PageAdr.pageIndex .contBox01 .contSubBox01 figure {
      margin-bottom: 50px; }
      #PageAdr.pageIndex .contBox01 .contSubBox01 figure a {
        margin-bottom: 20px; }
        #PageAdr.pageIndex .contBox01 .contSubBox01 figure a:last-of-type {
          margin-bottom: 0; }
    #PageAdr.pageIndex .contBox01 .contSubBox02 p {
      line-height: 1.75;
      margin-bottom: 30px; }
      #PageAdr.pageIndex .contBox01 .contSubBox02 p:last-of-type {
        margin-bottom: 0; }
  #PageAdr.pageIndex .contBox02 {
    padding: 0 15px 95px; }
    #PageAdr.pageIndex .contBox02 .contSubBox01 ul li {
      width: calc(calc(100% - 15px) / 3);
      margin-bottom: 6px; }
      #PageAdr.pageIndex .contBox02 .contSubBox01 ul li img {
        width: 100%;
        height: auto; }

  /*
  事務所案内
  -------------------------------------*/
  #PageOffice.pageIndex .titleTypeBg {
    padding: 22px 15px;
    font-size: 18px;
    margin-bottom: 30px; }
  #PageOffice.pageIndex .titleTypeBasic {
    padding: 10px 0;
    margin-bottom: 20px; }
    #PageOffice.pageIndex .titleTypeBasic + p {
      margin-bottom: 12px; }
  #PageOffice.pageIndex .titleTypeBorder {
    font-size: 18px;
    padding: 12px 27px;
    margin-bottom: 30px; }
    #PageOffice.pageIndex .titleTypeBorder span {
      font-size: 14px;
      position: relative;
      top: -2px; }
  #PageOffice.pageIndex .contBox01 ul {
    padding: 53px 15px 60px; }
    #PageOffice.pageIndex .contBox01 ul li {
      margin-bottom: 7px; }
      #PageOffice.pageIndex .contBox01 ul li:last-of-type {
        margin-bottom: 0; }
      #PageOffice.pageIndex .contBox01 ul li a {
        text-align: left;
        padding-right: 16px;
        background-color: #f5f5f5;
        background-size: 12px auto;
        background-position: right 15px center;
        display: block;
        padding: 12px 20px; }
  #PageOffice.pageIndex .contBox02 .contSubBox01 {
    padding: 0 15px 20px; }
    #PageOffice.pageIndex .contBox02 .contSubBox01 img {
      width: 175px;
      height: auto;
      margin: 0 auto;
      display: block; }
    #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox {
      padding-top: 20px; }
      #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox p {
        line-height: 1.8;
        margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox .align-right {
        font-size: 16px; }
        #PageOffice.pageIndex .contBox02 .contSubBox01 .txtBox .align-right span {
          font-size: 14px; }
  #PageOffice.pageIndex .contBox03 .titleTypeBg {
    margin-bottom: 40px; }
  #PageOffice.pageIndex .contBox03 .contSubBox01,
  #PageOffice.pageIndex .contBox03 .contSubBox02 {
    padding: 0 15px; }
    #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul,
    #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul {
      margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul li,
      #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul li {
        margin-bottom: 2px; }
        #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul li:last-of-type,
        #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul li:last-of-type {
          margin-bottom: 0; }
        #PageOffice.pageIndex .contBox03 .contSubBox01 .txtBox ul li.dotTypeBasic::after,
        #PageOffice.pageIndex .contBox03 .contSubBox02 .txtBox ul li.dotTypeBasic::after {
          top: 13px; }
  #PageOffice.pageIndex .contBox03 .contSubBox01 {
    margin-bottom: 33px; }
  #PageOffice.pageIndex .contBox03 .contSubBox02 {
    margin-bottom: 30px; }
  #PageOffice.pageIndex .contBox03 .contSubBox03 {
    background-color: #f5f5f5;
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: 34px 16px; }
    #PageOffice.pageIndex .contBox03 .contSubBox03 ul {
      margin-bottom: 15px; }
      #PageOffice.pageIndex .contBox03 .contSubBox03 ul:last-of-type {
        margin-bottom: 0; }
      #PageOffice.pageIndex .contBox03 .contSubBox03 ul li {
        margin-bottom: 20px; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li:last-of-type {
          margin-bottom: 0; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li dl dt {
          font-size: 16px;
          line-height: 1.5; }
        #PageOffice.pageIndex .contBox03 .contSubBox03 ul li dl dd {
          padding-left: 25px;
          line-height: 1.8; }
  #PageOffice.pageIndex .contBox03 .contSubBox04 {
    padding: 40px 15px 55px; }
    #PageOffice.pageIndex .contBox03 .contSubBox04 ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      #PageOffice.pageIndex .contBox03 .contSubBox04 ul li {
        width: calc(calc(100% - 20px) / 4);
        margin-bottom: 5px; }
        #PageOffice.pageIndex .contBox03 .contSubBox04 ul li img {
          width: 100%;
          height: auto; }
  #PageOffice.pageIndex .contBox04 .titleTypeBg {
    margin-bottom: 40px; }
  #PageOffice.pageIndex .contBox04 .contSubBox {
    padding: 0 15px; }
    #PageOffice.pageIndex .contBox04 .contSubBox .wrap {
      margin-bottom: 40px; }
    #PageOffice.pageIndex .contBox04 .contSubBox img {
      width: 175px;
      height: auto;
      margin: 0 auto 30px;
      display: block; }
    #PageOffice.pageIndex .contBox04 .contSubBox table tr th {
      width: 32%;
      padding: 12px 15px;
      font-size: 14px; }
    #PageOffice.pageIndex .contBox04 .contSubBox table tr td {
      width: 68%;
      padding: 12px 30px 12px 0;
      font-size: 16px; }
  #PageOffice.pageIndex .contBox04 .contSubBox02 {
    margin-bottom: 60px; }
  #PageOffice.pageIndex .contBox05::after {
    height: calc(100% - 335px);
    top: 335px; }
  #PageOffice.pageIndex .contBox05 .titleTypeBg {
    margin-bottom: 35px; }
  #PageOffice.pageIndex .contBox05 .contSubBox01 {
    padding: 0 15px; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 h4 {
      font-size: 18px;
      margin-bottom: 5px; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 > p {
      font-size: 14px;
      margin-bottom: 22px;
      line-height: 1.8; }
    #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox {
      padding: 20px 0 35px; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 {
        padding: 0 15px;
        width: calc(100% - 30px);
        margin: 0 auto;
        padding-bottom: 22px; }
        #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .tel {
          font-size: 32px; }
        #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box01 .fax {
          margin-top: -12px; }
      #PageOffice.pageIndex .contBox05 .contSubBox01 .txtBox .box02 .mail {
        margin-top: 3px; }
  #PageOffice.pageIndex .contBox05 .contSubBox02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 15px 30px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box {
      width: calc(50% - 8px); }
      #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox {
        padding: 23px 10px; }
        #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox h4 {
          font-size: 18px; }
        #PageOffice.pageIndex .contBox05 .contSubBox02 .box .txtBox p {
          font-size: 14px;
          line-height: 1.8; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box01 .txtBox::after {
      background: url(../img/contents/office/arrow02_sp.png) no-repeat center center;
      background-size: 31px auto;
      width: 31px;
      height: 130px;
      bottom: -131px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box02 .txtBox::after {
      background: url(../img/contents/office/arrow03.png) no-repeat center center;
      background-size: 31px auto;
      width: 31px;
      height: 16px;
      bottom: -24px; }
    #PageOffice.pageIndex .contBox05 .contSubBox02 .box02 .txtBox:nth-of-type(1) {
      margin-bottom: 30px; }
      #PageOffice.pageIndex .contBox05 .contSubBox02 .box02 .txtBox:nth-of-type(1) h4 {
        letter-spacing: 0em; }
  #PageOffice.pageIndex .contBox05 .contSubBox03 {
    padding: 33px 20px 40px;
    background-color: #fff;
    border: 1px solid #552b11;
    width: calc(100% - 30px);
    margin: 0 auto; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 + p {
      font-size: 14px;
      margin-top: 10px;
      padding: 0 15px;
      line-height: 1.8; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead {
      padding-bottom: 37px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead p {
        text-align: center;
        margin-bottom: 12px;
        line-height: 1.8; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul {
        margin-bottom: 12px; }
        #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul li {
          background-color: #cc9b30;
          color: #fff;
          text-align: center;
          padding: 8px 0;
          margin-bottom: 5px; }
          #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxHead ul li:last-of-type {
            margin-right: 0; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .titleTypeBar::after {
      top: -24px; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox {
      padding: 27px 15px 24px;
      margin-bottom: 32px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox h5 {
        font-size: 18px;
        margin-bottom: 6px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtBox p {
        line-height: 1.8;
        text-align: justify; }
    #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li {
      width: calc(calc(100% - 20px) / 3);
      padding: 23px 0;
      font-size: 16px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li:nth-of-type(1) {
        padding-top: 35px; }
      #PageOffice.pageIndex .contBox05 .contSubBox03 .txtBoxBody .txtList li::after {
        background-size: 32px auto;
        width: 32px;
        height: 15px;
        top: -22px; }
  #PageOffice.pageIndex .contBox05 .contSubBox04 {
    padding: 53px 0 50px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 .titleTypeBgYe {
      font-size: 18px;
      padding: 16px 15px;
      margin-bottom: 23px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li p {
      margin-bottom: 25px;
      padding: 0 15px;
      line-height: 1.8; }
      #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li p:last-of-type {
        margin-bottom: 45px; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li:last-of-type p:last-of-type {
      margin-bottom: 0; }
    #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul {
      margin-bottom: 30px;
      padding: 0 15px; }
      #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul > li {
        margin-bottom: 8px;
        line-height: 1.8; }
        #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul > li.dotTypeBasic::after {
          top: 12px; }
        #PageOffice.pageIndex .contBox05 .contSubBox04 > ul > li > ul > li:last-of-type {
          margin-bottom: 0; }
  #PageOffice.pageIndex .contBox06 {
    padding: 0 0 70px; }
    #PageOffice.pageIndex .contBox06 .titleTypeBg {
      margin-bottom: 20px; }
    #PageOffice.pageIndex .contBox06 .contSubBox01 {
      padding: 0 15px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 p {
        margin-bottom: 25px;
        line-height: 1.8; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 iframe {
        width: 100%;
        height: 220px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 .linkTypeBlank {
        width: 150px;
        margin-bottom: 32px; }
      #PageOffice.pageIndex .contBox06 .contSubBox01 ul {
        margin-bottom: 15px; }
        #PageOffice.pageIndex .contBox06 .contSubBox01 ul + p {
          font-size: 14px; }
        #PageOffice.pageIndex .contBox06 .contSubBox01 ul li {
          line-height: 1.8; }
          #PageOffice.pageIndex .contBox06 .contSubBox01 ul li.dotTypeBasic::after {
            top: 13px; }

  /*
  著書・論文
  -------------------------------------*/
  #PageBooks.pageIndex .titleTypeBg {
    font-size: 18px;
    padding: 22px 15px; }
  #PageBooks.pageIndex .titleTypeBasic {
    font-size: 18px;
    padding: 11px 0;
    margin-bottom: 20px; }
  #PageBooks.pageIndex .contBox01 {
    padding: 55px 15px 60px; }
    #PageBooks.pageIndex .contBox01 ul {
      justify-content: space-between; }
      #PageBooks.pageIndex .contBox01 ul li {
        background-color: #f5f5f5;
        width: calc(50% - 3px); }
        #PageBooks.pageIndex .contBox01 ul li:last-of-type {
          margin-right: 0; }
        #PageBooks.pageIndex .contBox01 ul li a {
          padding: 12px 20px;
          background-size: 12px auto;
          background-position: right 15px center; }
  #PageBooks.pageIndex .contBox02 .contSubBox01 {
    padding: 10px 15px 60px; }
    #PageBooks.pageIndex .contBox02 .contSubBox01 ul li {
      border-bottom: 1px solid #d0d0d0;
      padding: 40px 0 20px; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li figure {
        width: 167px;
        margin: 0 auto 20px; }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li figure img {
          width: 100%;
          height: auto; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .bookTitle {
        margin-bottom: 14px; }
        #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .bookTitle span {
          font-size: 18px;
          font-weight: 700;
          color: #552b11; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox p {
        line-height: 1.8;
        margin-bottom: 20px; }
      #PageBooks.pageIndex .contBox02 .contSubBox01 ul li .txtBox .align-right {
        font-size: 14px; }
  #PageBooks.pageIndex .contBox03 {
    padding-bottom: 80px; }
    #PageBooks.pageIndex .contBox03 .contSubBox01 {
      padding: 30px 15px 40px; }
      #PageBooks.pageIndex .contBox03 .contSubBox01 ul li {
        line-height: 1.8;
        margin-bottom: 4px; }
        #PageBooks.pageIndex .contBox03 .contSubBox01 ul li.dotTypeBasic::after {
          top: 10px; }
    #PageBooks.pageIndex .contBox03 .contSubBox02 {
      padding: 0 15px; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul.listBar {
        margin-bottom: 12px; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul li {
        line-height: 1.8; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul .dotTypeBasic {
        margin-bottom: 8px; }
        #PageBooks.pageIndex .contBox03 .contSubBox02 ul .dotTypeBasic::after {
          top: 12px; }
      #PageBooks.pageIndex .contBox03 .contSubBox02 ul .dotTypeBar {
        margin-left: 23px;
        margin-bottom: 6px; }
        #PageBooks.pageIndex .contBox03 .contSubBox02 ul .dotTypeBar::after {
          top: 15px; }

  /*
  後藤孝典が語る ヘッダー・メインイメージ
  -------------------------------------*/
  #PageGototakanori #MenuButton {
    display: none; }
  #PageGototakanori #MainImg {
    height: 405px; }
    #PageGototakanori #MainImg #MainImgInner {
      position: relative;
      height: 100%; }
      #PageGototakanori #MainImg #MainImgInner .imgBox {
        height: 194px; }
      #PageGototakanori #MainImg #MainImgInner .innerBasic {
        padding: 18px 15px 22px; }
      #PageGototakanori #MainImg #MainImgInner h2 {
        margin: 0 auto 3px;
        padding-top: 26px;
        text-align: left;
        font-size: 26px;
        background-size: 24px auto; }
        #PageGototakanori #MainImg #MainImgInner h2.titleTypeMainVisu[data-subtitle]::after {
          top: 0;
          padding-left: 35px; }
        #PageGototakanori #MainImg #MainImgInner h2::before {
          left: 94px;
          top: 13px;
          width: 35px; }
      #PageGototakanori #MainImg #MainImgInner p {
        font-size: 10px; }

  /*
  後藤孝典が語る
  -------------------------------------*/
  #PageGototakanori.pageIndex .contBox01 {
    padding: 30px 15px 210px; }
    #PageGototakanori.pageIndex .contBox01 .contSubBox01 {
      margin: 0 auto; }
      #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post {
        margin-bottom: 55px; }
        #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont {
          padding: 40px 20px 50px;
          margin-bottom: 30px; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont:last-of-type {
            margin-bottom: 0; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .date {
            margin-bottom: 0; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .title {
            font-size: 18px;
            margin-bottom: 15px; }
          #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .entryBody {
            margin-bottom: 40px; }
            #PageGototakanori.pageIndex .contBox01 .contSubBox01 .post .postCont .entryBody p {
              line-height: 1.8; }

  /*
  後藤孝典が語る（記事詳細）
  -------------------------------------*/
  #PageGototakanori.pageEntry .contBox01 {
    padding: 30px 15px 130px; }
    #PageGototakanori.pageEntry .contBox01 .contSubBox01 {
      margin: 0 auto 67px;
      padding: 40px 20px 50px; }
      #PageGototakanori.pageEntry .contBox01 .contSubBox01 .date {
        margin-bottom: 10px; }
      #PageGototakanori.pageEntry .contBox01 .contSubBox01 .titleTypeBasic {
        padding: 15px 0;
        margin-bottom: 50px; }

  /*
  お知らせブログ（カテゴリ）
  -------------------------------------*/
  #PageBlog.pageCategory .contBox01 {
    padding-bottom: 210px; }
    #PageBlog.pageCategory .contBox01 .entryType01 {
      padding: 50px 0 35px; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl a {
        padding: 25px 0; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl .date {
        width: 115px;
        padding: 0 15px; }
      #PageBlog.pageCategory .contBox01 .entryType01 dl .title {
        width: calc(100% - 115px);
        padding: 0 20px 0 0; }
    #PageBlog.pageCategory .contBox01 .entryType02 {
      padding: 50px 0 35px;
      width: calc(100% - 30px);
      margin: 0 auto;
      overflow: hidden; }
      #PageBlog.pageCategory .contBox01 .entryType02 .post {
        margin-bottom: 55px; }
        #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont {
          padding: 40px 20px 50px;
          margin-bottom: 30px; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont:last-of-type {
            margin-bottom: 0; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .date {
            margin-bottom: 0; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .title {
            font-size: 18px;
            margin-bottom: 15px; }
          #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .entryBody {
            margin-bottom: 40px; }
            #PageBlog.pageCategory .contBox01 .entryType02 .post .postCont .entryBody p {
              line-height: 1.8; }

  /*
  お知らせブログ（記事詳細）
  -------------------------------------*/
  #PageBlog.pageEntry .contBox01 {
    padding: 52px 15px 150px; }
    #PageBlog.pageEntry .contBox01 .contSubBox01 {
      padding: 38px 20px 55px;
      margin: 0 auto 65px;
      overflow: hidden; }
      #PageBlog.pageEntry .contBox01 .contSubBox01 .date {
        margin-bottom: 12px; }
      #PageBlog.pageEntry .contBox01 .contSubBox01 .titleTypeBasic {
        padding: 12px 0;
        margin-bottom: 20px; }

  /*
  お問い合わせ（共通）
  -------------------------------------*/
  #PageContact #Main .innerBasic {
    padding: 49px 15px 100px; }
  #PageContact #Main .formBox .tableTypeForm {
    display: block;
    margin-bottom: 60px; }
    #PageContact #Main .formBox .tableTypeForm tbody, #PageContact #Main .formBox .tableTypeForm tr, #PageContact #Main .formBox .tableTypeForm th, #PageContact #Main .formBox .tableTypeForm td {
      display: block; }
    #PageContact #Main .formBox .tableTypeForm tbody tr {
      margin-bottom: 27px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr:last-of-type {
        margin-bottom: 0; }
      #PageContact #Main .formBox .tableTypeForm tbody tr th {
        padding-bottom: 14px;
        padding-left: 48px; }
        #PageContact #Main .formBox .tableTypeForm tbody tr th::before {
          top: 3px;
          padding: 4px;
          width: 40px;
          height: 21px;
          font-size: 12px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="text"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="email"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td input[type="tel"],
      #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
        padding: 12px 12px; }
      #PageContact #Main .formBox .tableTypeForm tbody tr td textarea {
        height: 210px; }
  #PageContact #Main .listTypeButton li.submit .btnTypeBasic, #PageContact #Main .listTypeButton li.back .btnTypeBasic {
    margin-top: 0;
    margin-right: auto;
    padding-bottom: 0;
    width: 300px;
    height: 70px;
    letter-spacing: 0;
    font-size: 16px; }

  /*
  お問い合わせ（入力画面）
  -------------------------------------*/
  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm #Main .formBox .tableTypeForm {
    margin-bottom: 51px; }
    #PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr {
      margin-bottom: 35px; }
      #PageContact.pageConfirm #Main .formBox .tableTypeForm tbody tr:nth-of-type(4) th::before {
        padding: 4px; }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks #Main .listTypeButton li.back .btnTypeBasic {
    padding-top: 21px; }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex #Main .contBox01 {
    padding: 44px 15px 95px; }
    #PagePolicy.pageIndex #Main .contBox01 p {
      line-height: 1.8; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox01 {
      margin-bottom: 53px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 .titleTypeBasic {
      font-size: 18px;
      padding: 11px 0;
      margin-top: 50px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 p {
      margin-top: 22px; }
    #PagePolicy.pageIndex #Main .contBox01 .contSubBox02 ul {
      margin-top: 23px; } }
@media print, screen and (max-width: 370px) {
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list01 a {
    font-size: 18px; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a {
    font-size: 14px;
    padding-right: 40px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a:nth-of-type(1) {
      background-position: bottom 18px right 10px; }
    #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list02 a small {
      font-size: 10px; }
  #Page.pageIndex #ContBox03 .contSubBox01 .blogList .list03 a {
    font-size: 16px; } }
